/* eslint-disable max-len */
export default {
  name: 'Users',
  title: 'OFFICE - USERS',
  no_items: 'No users',
  no_by_letter: 'No users found by letter "{{ letter }}"',
  no_by_letter_and_request: 'Nothing found for "{{ request }}" and "{{ letter }}"',
  go_artworks: 'Go to artworks',
  market: 'Artworks on the market',
  main_market: 'Market',
};
