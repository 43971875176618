/* eslint-disable max-len */
export default {
  title: 'My account - news',
  title_post: 'My account - news',
  name: 'Post',
  updated: 'Your post is updated',
  published: 'Your post is published',
  sure_delete: 'Are you sure you want to delete your post?',
  recovery_impossible: 'Recovering will not be possible',
  send_abuse: 'Are you sure you want to report this post?',
  recive_abuse: 'The post is reported!',
  text_required: '{{ name }}, for publishing, please, fill in the text',
  user: 'User',
  news: 'News',
  empty: 'No new posts by friends',
  create: 'Create a post',
  share_post: '{{ user }}, share your news for friends!',
  publish_info: 'It will be published with your comment',
  remove: 'Delete the post',
  abuse: 'Report',
  open: 'Open in new tab',
  link: 'link',
  user_empty: 'The user has no publications',
  user_end: 'The user has no more publications',
  interesting: 'Interesting?',
  interesting_text: 'Viewing content is available only for registered users. Login to the platform and get unlimited access to interesting materials about art.',
  login_register: 'Login or register',
};
