/* eslint-disable max-len */
export default {
  name: 'Education',
  bye: 'Purchase course',
  about: 'About course',
  program: 'Course program',
  authors: 'Authors',
  author: 'Author',
  who_needs: 'Suitable for whom',
  will_learn: 'What will you learn',
  alumni_reviews: 'Alumni reviews',
  cost: 'Cost',
  auth_pay: 'Log in and buy a course',
  pay_installments: 'Purchase in installments',
  login_pay: 'To purchase a course, login or register!',
  success_payed: 'The course has been successfully purchased',
  error_payment: 'Payment error, if the money is debited, but nothing happened, contact the administrator',
  go_my_education: 'Proceed to study',
  send_review: 'Leave a review',
  review_description: 'Comment',
  review_description_placeholder: 'Enter a comment',
  review_send: 'Send a review',
  review_sending: 'Sending',
  review_success: 'Thank you, your review has been sent successfully. After verification, it will become available',
  have_promo: 'I have a promo code',
  promo_code: 'Promo code',
  apply: 'Apply',
  checking: 'Checking',
  promo_success: 'Promo code successfully applied',
  lecture: 'lecture',
  lectures: 'lectures',
  lectures_2: 'lectures',
  lesson_lenght: 'Lesson length',
  course_lectors: 'Course lecturers',
  course_lector: 'Course lecturer',
  close: 'Close',
  credit: 'Buy in installments',
  lecturer: 'Speaker',
  lecturers: 'Speakers',
  back: 'Back',
  next: 'Forward',
  materials: 'Additional materials',
  video: 'Video',
  anons: 'Description',
  to_contents: 'To Contents',
  allow_tariff: 'Available for the tariff',
  allow_tariffs: 'Available for tariffs',
  faq: 'FAQ',
  alumni_video_reviews: 'Video reviews',
  pay: 'Pay',
  pay_all: 'Pay full',
  pay_european: 'Pay in euro',
  start_date: 'Start date',
  price: 'Price',
  all_course: 'All course',
  select_payment: 'Select payment option',
  by_module: 'By module',
  one_module: 'One module to choose from',
  select_module: 'Select any module from the course if you only want to study it.',
  select_info: 'After purchasing the entire course, the cost of this module will be deducted.',
  price_modules: 'Price including previously purchased modules. The discount only for half of the purchased modules',
  paid_modules_on: 'The cost for already purchased modules is',
  is_read: 'Passed',
  is_process: 'In Process',
  all: 'See all educational products in the Academy',
  info_module: 'When purchasing modules, the cost may be more expensive than according to the tariff. When purchasing tariff later, the cost will be taken of only half of the previously purchased modules for discount',
  'admin message': 'Hello! I have some questions about the course "{{ name }}"',
  have_questions: 'How can we help?',
  can_bye_module: 'This module can be purchased separately for',
  go_educations: 'Go to all courses',
  not_started: "Course hasn't started yet!",
  hidden_tariff: 'Explore tariffs',
  select_variant: 'Select payment option for the tariff "{{ name }}"',
  variant_modules: 'Select a payment option to purchase the following modules',
  total: 'Total',
};
