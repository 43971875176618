/* eslint-disable max-len */
export default {
  register_to_liked: 'Log in or create an account to put like',
  register_to_favorites: 'Log in or create an account to add to favourites',
  error: 'An error has occurred. Please, try again later',
  url_copy: 'URL is successfully copied',
  device_not_support: "Sorry, your device doesn't support this feature",
  message_send_success: 'Success! Your message is sent, thank you!',
  success_add_favorites: 'Successfully added to favourites',
  success_remove_favorites: 'Successfully deleted from favourites',
  offer_updated: 'Your offer is updated',
  offer_deleted: 'Your offer is deleted',
  offer_sent_curator: 'The offer is successfully sent to the curator!',
  attach_artworks: 'It is necessary to select artworks!',
  offer_artwork_remove: 'Your offer is deleted! Anyway, the curator has already been notified and will possibly contact you',
  offered_exhibition: 'The offer is successfully sent to the curator!',
  select_exhibition: 'It is necessary to select an event',
  token_not_exist: "Such token doesn't exist",
  email_changed: 'Email is changed successfully',
  password_cleared: 'The password is reset, check your email',
  password_changed: 'Your password is changed successfully!',
  admin_error: 'Something went wrong. Please contact the administration',
  send_instructions: 'Please, check your email and follow the sent instructions',
  registration_complete: 'Your account is successfully created!',
  account_changed: 'Your account is successfully changed!',
  data_save: 'Data is saved',
  error_update_profile: 'Profile update error!',
  on_moderation: "The item is currently being moderated, you can't edit it!",
  you_not_auth: 'You are not authorized for these actions',
  you_owner: 'You owner',
  abuse_send: 'Abuse sent success!',
  test_mode: 'You are in view mode, to return to your personal view press "Return to editing"',
};
