/* eslint-disable max-len */
export default {
  title: 'My account - Locations',
  label: 'Locations',
  my: 'My locations',
  create_name: 'Create a new location',
  placeholder_search: 'Enter a location name',
  show: 'Location',
  showed: 'Location',
  type_selected: 'Chosen location',
  type_my: 'Choose from my locations',
  type_my_info: "It's fine to give only a name of the location. This place will be automatically saved in your locations",
  type_required_info: 'Name and description are just only for you. The address is required to determine the location of the event',
  type_select_info: 'You can choose it from your locations or create a new one',
  change: 'Change the location',
  create: 'Create a new location',
  name: 'Name of the place',
  name_required: 'Name required!',
  name_placeholder: 'Enter a name',
  address: 'Address',
  address_placeholder: 'Enter an address (not required)',
  address_placeholder_require: 'Enter an address',
  updated: 'You location is successfully updated!',
  added: 'ВYou location is successfully added!',
  description: 'Description',
  description_placeholder: 'Brief description of a location (not required)',
  sure_delete: 'Are you sure you want to delete the location {{ name }}?',
  sure_delete_info: 'It will also be deleted from all the artworks to which it is attached.',
  empty_text: "You haven't created any locations yet",
  empty_create: 'Would you like to create?',
  empty_add: 'Add a location',
  by_request_empty: 'No results for the request "{{ search }}"',
  no_address: 'No address',
  error_coordinates: 'Select search location',
  all_places_near: 'All places',
  distances: 'Radius km.',
  browser_place: 'Your location get from browser',
  default_place: 'Your current position is not defined, the default is set Moscow',
  selected_place: 'Place which you selected',
  on_map: 'map',
  hide_map: 'hide map',
  reset_result: 'Reset the current result',
};
