/* eslint-disable max-len */
export default {
  crumbs: 'Exhibitions',
  publish: 'Exhibition',
  my_name: 'My exhibition',
  title: 'Event "{{ name }}" | Artocratia',
  description: 'Event - {{ name }}{{ dates }}. Social network for art lovers - Artocratia',
  count_artworks: 'Artworks on exhibition',
  type: 'The type of event',
  type_placeholder: 'Select the type',
  date: 'Date',
  date_placeholder: 'Select the duration',
  time: 'Time',
  select_days: 'Select the dates',
  location: 'Location',
  work_time_date: 'Working hours',
  work_days: 'Workdays',
  address: 'Address',
  no_address: 'Address is not specified',
  gallery: 'Gallery',
  curator: 'Curator',
  others: 'Other events of this curator',
  share_label: 'Share the event',
  share_description: 'Event - {{ name }}{{ dates }}. Social network for art lovers - Artocratia',
  files: 'Additional materials',
  sure_delete: 'Are you sure you want to delete the event {{ name }}?',
  recovery_impossible: 'Recovering will not be possible',
  empty_text: "You haven't created an exhibition yet",
  empty_create: 'Would you like to create?',
  empty_add: 'Add an exhibition',
  cabinet_title: 'My account - my exhibitions',
  my: 'My exhibitions',
  create: 'Create an exhibition',
  search_placeholder: 'Enter a name',
  why_not: "It doesn't have a photo and necessary information",
  alert_delete_use: "You can't delete this event because it has participation requests or it is used in artworks",
  alert_delete_moderation: "You can't change this element because it's currently being moderated",
  alert_publish: "You can't publish this artwork because it has no photo or description",
  requests: 'New requests',
  requests_switch_off: 'Requests are switched off',
  requests_name: 'Event requests',
  information: 'Event information',
  offer_artworks: 'Offer artworks',
  user_offer_artworks: 'offers this artwork',
  work_name: 'Name',
  was_save: "You've successfully saved the event",
  update: 'Update the event {{ name }}',
  add: 'Add an event',
  name: 'Name',
  name_placeholder: 'Enter a name',
  institution: 'Institution',
  institution_info: 'Сhoose a place from the existing ones or add a new one if it is an institution. You can fill in the information about it in the Museums section',
  institution_placeholder: 'Enter a name of an institution',
  desc: 'Description of the event',
  desc_placeholder: 'Enter a description',
  contacts: 'Contact information',
  curator_visibility: 'Show you as a curator of this event',
  curator_hide: 'Hide',
  curator_show: 'Show',
  images: 'Photos of the event',
  images_info: 'High resolution photos are required, not less than 1000х512 px and not larger than 5MB. The first photo is the main one for the cards',
  files_info: 'Presentations and other materials you want to share with everyone, each file is not larger than 10MB',
  can_offer: 'Collectors can send their artworks',
  cant_offer: 'No',
  online: 'Event option',
  online_placeholder: 'Select event option',
  pictures: 'Event cover',
  pictures_info: 'The event cover must be of good quality, not less than 1000 x 512 px and file is not larger than 2 MB',
  artworks: 'Artworks at exhibition ({{ total }})',
  artworks_placeholder: 'Enter name or artist',
  clear_artworks: 'Remove all artworks',
  sure_clear: 'Are you sure you want to remove all artworks from exhibition?',
  clear_info: 'You can always reassemble',
  offer_name: 'Artworks attached to the event',
  access_message: 'The exhibition is available only by link, do not pass it on to anyone, remember about confidentiality of the transmitted data',
  moderated: 'Submitting an event for moderation',
  moderated_info: 'Are you sure to submit an event for moderation, without publishing?',
  send_offer: 'Sending offers to collectors',
  offer_info: 'Are you sure to send collectors of added works an offer to participate in the event?',
  is_online: 'Online',
  is_offline: 'Offline',
  empty_to_delete: 'You have no attached artworks to delete',
  empty_ability_delete: 'You have no more artworks that can be deleted',
  deleted_works: 'Deleted {{ count }} {{ declination }}',
  sent_offer_collectors: 'You have sent an offer to collectors of {{count}} {{declination}}, please await a response',
  empty_attached: 'You have no attached artworks',
  previously_sent: 'You have previously sent proposals to all participants, please wait for a response',
  sent_moderation: 'You have sent an event for moderation, please wait for a response',
  fill_name: 'Fill the title',
  fill_description: 'Fill the description',
  attach_picture: 'Upload an image',
  fill_dates: 'Set the dates of the event',
  cant_change_type_sent_works: "You can't change the type of event as you have artworks which you sen to collectors",
  cant_change_type_view_works: "Since the event status can be viewed by other users you can't change its type",
  select_online: 'Select artworks from Artocratia that you want to have in your online exhibition',
  select_offer: 'Select works from Artocratia that you plan to invite to participate in the exhibition',
  after_attach_send_offer: 'After adding artworks, you can send a request to participate in the exhibition, collectors and get a response from them',
  attach_for_view_online: 'You can attach artworks to the online exhibition that will be displayed below it. It is also possible to publish it with access by link without publishing',
  to_send_offer: 'To send offers',
  sent_yet: 'You have already sent offers for {{ count }} {{ declination }}',
  await_after_sent: 'After submitting bids, await artwork confirmation or communication from collectors. Do not delete artworks or change publication status, otherwise collectors will not be able to contact or confirm participation in the event',
  send_offers: 'Send job owners an offer to take part in the event',
  share_link: 'You can share a link to the event, but access is only possible through it, if you change the publication option, the link will be removed',
  before_offer_moderation: 'To be able to send proposals to collectors of attached artworks, with access to the event via the link, submit it for moderation without publishing',
  for_send_moderation: 'To send for moderation without publishing, to send proposals',
  send_moderation: 'Send for moderation without posting',
  on_moderation: 'The event is under moderation, after successful completion, the opportunity to send proposals will be available',
  to_get_opportunity_offer: 'To be able to send suggestions to collectors of attached artworks, you can send an event for publication or create access via a link and then send it for moderation. To get moderation, the event must be as full as possible',
  after_attach_online_info: 'After adding works, you can create access by link or publish an online event for everyone',
  provided_access_event: 'You have provided access to the event via a link. To remove access, select Unpublish',
  publish_all: 'To publish to everyone',
  title_confirm: 'Confirmation of participation of all artworks',
  message_confirm: 'Are you sure you want to confirm all artworks?',
  alert_confirm: 'All artworks are confirmed, wait for the contact of the curator or write to him yourself',
  title_confirm_selected: 'Confirmation of participation of the selected artworks',
  message_confirm_selected: 'Sure that you want to confirm only the selected artworks? The rest will be deleted',
  alert_confirm_selected_yes: 'Selected artworks have been confirmed, wait for the contact of the curator or write to him yourself',
  alert_confirm_selected_no: 'You have not selected artworks',
  title_remove: 'Removing all entries from the application',
  message_remove: 'Are you sure you want to remove all entries from the request?',
  alert_remove: 'All artworks have been removed from the request',
  your_works: 'Your artworks at the event',
  selected_works: 'Selected {{ count }} {{ declination }}',
  confirm_all: 'Confirm all artworks',
  confirm_selected: 'Confirm the participation of the selected',
  remove_all: 'Remove all artworks from participation',
  select_action: 'Select action',
  show_my: 'Show only my artworks',
  show_all: 'Show all artworks',
  all: 'My exhibitions',
  location_show: 'Location',
  location_type_select_info: 'You can choose it from your locations or create a new one. It will be in your location section',
  location_type_my: 'Choose from my locations',
  until: 'Until',
  close_add: 'Publish exhibitions using the <em>{{ name }}</em> subscription to Artocratia',
  hidden_tariff: 'Explore tariffs',
};
