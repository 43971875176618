/* eslint-disable import/no-extraneous-dependencies,react/forbid-prop-types,no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useSubscription } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'react-use';
import styled from 'styled-components';

import { GQL_COMMENT_NEW, GQL_GET_NOTIFICATIONS_COUNT } from '../../../graphql/queries';
import { DARK, DEFAULT_LIGHT } from '../../../helpers/colors';
import { cutText } from '../../../helpers/main';
import { useTranslation } from '../../hooks/useTranslation';

const TIMEOUT_VISIBLE = 10000;

const NewComments = ({ me }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [items, setItems] = useState([]);
  const prev = usePrevious(items) || [];

  const { refetch } = useQuery(GQL_GET_NOTIFICATIONS_COUNT);

  const { data: dataSubscription } = useSubscription(GQL_COMMENT_NEW, {
    variables: { receiver_id: _.get(me, '_id') },
  });
  const commentNew = _.get(dataSubscription, 'commentNew');

  // add comment
  useEffect(() => {
    if (commentNew) {
      const audio = new Audio('/sounds/beep.mp3');
      refetch();
      audio.load();
      audio.play();
      setItems((prevItems) => [...prevItems, { ...commentNew, show: false }]);
    }
    return () => {
      setItems([]);
    };
  }, [commentNew]);

  useEffect(() => {
    if (items.length > prev.length && !_.last(items).show) {
      // show the latest message
      setItems((prevItems) => [
        ...prevItems.filter((i) => i._id !== _.last(prevItems)._id),
        { ..._.last(prevItems), show: true },
      ]);

      // remove old messages from state
      setTimeout(() => {
        setItems((prevItems) => prevItems.filter((i) => moment().diff(moment(+i.created_at), 'seconds') < 5));
      }, TIMEOUT_VISIBLE);
    }
  }, [items]);

  return (
    <ToastArea>
      {items.map((item) => item.show && (
        <Toast key={`notificationComment-${item._id}`}>
          <ToastInfo>
            <UserInfo>
              <UserImage
                src={_.get(item, 'owner.avatar.key', '')
                  ? `/files/${_.get(item, 'owner.avatar.key', '')}`
                  : '/images/no-avatar.jpg'}
                alt={_.get(item, 'owner.name')}
              />
              <div>
                {_.get(item, 'owner.name')}
                <Time>{moment.unix(item.created_at).fromNow()}</Time>
              </div>
            </UserInfo>
            <ButtonClose
              type="button"
              onClick={() => {
                setItems((prevItems) => [
                  ...prevItems.map((i) => ({
                    ...i,
                    show: i._id === item._id ? false : i.show,
                  })),
                ]);
              }}
            >
              &times;
            </ButtonClose>
          </ToastInfo>
          <ToastContent onClick={() => history.push(item.url)}>
            {item.replyId ? t('push_reply', { ns: 'comments' }) : t('push_wright', { ns: 'comments' })}
            {': '}
            <i>{cutText(item.text, 100)}</i>
          </ToastContent>
        </Toast>
      ))}
    </ToastArea>
  );
};

const ButtonClose = styled.button`
  z-index: 1600;
  position: absolute;
  right: 0;
  top: 6px;
  font-size: 45px;
  display: block;
  padding: 10px;
  color: #000;
  border: 0;
  font-weight: 100;
  line-height: 0;
`;

const Time = styled.div`
  margin-top: -10px;
  font-size: 12px;
  color: ${DEFAULT_LIGHT};
`;

const ToastArea = styled.div`
  position: fixed;
  width: 100%;
  max-width: 400px;
  right: 20px;
  top: 20px;
  z-index: 1101;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 10px;
  @media screen and (max-width: 812px) {
    right: 0;
    top: 30px;
  }
`;

const UserImage = styled.img`
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
  border-radius: 50%;
`;

const Toast = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  background: white;
  box-shadow: 0 0 4px 1px #a7a7a7;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const ToastInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ToastContent = styled.button`
  padding: 0;
  font-size: 80%;
  line-height: 1.2;
  text-align: left;
  color: ${DARK};
`;

NewComments.propTypes = {
  me: PropTypes.object.isRequired,
};

export default NewComments;
