/* eslint-disable max-len */
export default {
  crumb: 'Galleries and museums',
  share_name: 'Share the museum',
  title: 'Institution - «{{ name }}» | Artocratia',
  description: 'Institution - "{{ name }}". Social network for art lovers - Artocratia',
  address: 'Address',
  curator: 'Curator',
  why_not: "You can't publish this institution as it doesn't have a photo and necessary information",
  alert_delete_moderation: "You can't change this element because it's currently being moderated",
  alert_publish: "You can't publish this artwork because it has no photo or name",
  alert_type: 'You must select the type of the institution',
  alert_address: 'Specify the address of the institution!',
  alert_save: "You've successfully saved the institution!",
  alert_published: 'The institution is currently being moderated, wait for approval!',
  sure: 'Are you sure you want to {{ action }} institution "{{ name }}"?',
  my_institutions: 'My institutions',
  my_institution: 'My institution',
  publish: 'institution',
  update: 'Update event "{{ name }}"',
  add: 'Add event',
  empty_add: 'Adding an institution',
  field_name: 'Name',
  placeholder_name: 'Enter name',
  field_description: 'Description of institutions',
  placeholder_description: 'Enter a description',
  field_type: 'Type of institution',
  placeholder_type: 'Select a type',
  field_address: 'address of the institution',
  field_contacts: 'Contact details',
  field_curator: 'Display you like curator',
  do_not_show: 'No show',
  show: 'Show',
  logo: 'Photography - logo',
  logo_info: 'Photo must be of good quality, at least 512х512рх and it more than 2MB',
  upload_logo: 'Upload image',
  upload_logo_info: 'button {:} or drag a file to this area',
  photos: 'Photos of institution',
  photos_info: 'Each photo must be good quality, at least 1000x512 px and no more than 5 MB. The first photo is the main for card',
  moderation: 'Institution is under moderation, after reviewing it will become available for editing',
  total_exhibitions: 'Exhibitions of the institution ({{ total }})',
  exhibitions_placeholder: 'Enter name',
  type_select_info: 'You can choose it from your locations or create a new one',
  type_my: 'Choose from my locations',
  to_publish: 'Required to publish',
  fill_logo: 'Upload "Logo"',
  fill: 'Fill "{{ name }}"',
};
