/* eslint-disable max-len */
export default {
  no_items: "You don't take any courses.",
  want_started: 'Want to get started?',
  education: 'course',
  education_2: 'courses',
  educations: 'courses',
  in_progress: 'In progress',
  was_passed: 'Finished',
  at_future: 'Not started yet',
  from: 'from',
  new: 'New course!',
  done: 'Done',
};
