/* eslint-disable max-len */
export default {
  title: 'CABINET - BASIC DATA',
  title_about: 'CABINET - ABOUT MY COLLECTION',
  name_about: 'About the collection',
  profile_data: 'Profile data',
  security: 'Security',
  companies: 'Companies',
  notifications: 'Notifications',
  change_type: 'Change account type',
  type: 'Current type {{type }}',
  main: 'Basic data',
  label_main: 'Home',
  label_artworks: 'Works',
  label_albums: 'Albums',
  label_artists: 'Artists',
  label_exhibitions: 'Exhibitions',
  label_institutions: 'Museums',
  label_contacts: 'Contacts',
  label_favourites: 'Favorites',
  label_locations: 'Locations',
  label_messages: 'Messages',
  label_events: 'Events',
  label_posts: 'News',
  upload_photo: 'Upload photo',
  remove_photo: 'Delete photo',
  change_type_btn: 'Change account type',
  name: 'First Name Last Name',
  name_info: 'This is how your name will be displayed publicly',
  name_placeholder: 'Enter first name last name',
  username: 'Login link',
  username_info: 'Login is used to log in and create a link to your profile',
  username_example: 'For example, if you enter the username `artocratia`, the link to your profile will look like {{link }}',
  username_link: 'Link to your profile',
  username_placeholder: 'Enter in Latin characters',
  about: 'About myself',
  about_info: 'For public description of your profile in the collection',
  about_placeholder: 'Enter a description',
  default_section: 'Home page',
  default_section_info: 'Here you can choose which page to load first when logging in',
  default_section_placeholder: 'Select the default section',
  contact_data: 'Data for communication',
  contact_info: 'Specify contacts for business offers. It can be work mail, messengers or social networks. The specified contacts will be visible to all registered users',
  json: 'Link for importing works from a JSON file',
  json_info: 'This link is from a resource that can transmit data about your work in the required format',
  json_download: 'Download an example of transmitted data in JSON format',
  label_artworks_artist: "Artist's works",
  language: 'Application language',
  language_info: 'Select the priority language in which notifications will be received',
  language_placeholder: 'Select a language',
  payments: 'Payments',
  auto_renewal_on: 'Auto-renewal',
  auto_renewal_info: 'Payments for the following services will be automatically debited by this card',
  renewal_off: 'Delete the card and disable auto-renewal',
  renewal_item_off: 'Turn off auto-renewal',
  card_name: 'Card',
  card_number: 'Card number',
  card_expiry: 'Expiration date',
  no_payments: "You don't have data for this section yet",
  success_delete: 'The card was successfully deleted, auto-payment is disabled',
  error_delete: 'Deletion error, contact the administrator!',
  collection: 'About the collection',
  collection_placeholder: 'Tell us briefly about the collection',
  collection_en: 'About the collection in English',
  collection_placeholder_en: 'Tell us about the collection in English',
  start: 'Start date',
  start_placeholder: 'Enter the year',
  wrap: 'Collection cover',
  wrap_info: 'Set your own, individual image of the collection. </br> By default, this is the image of the last available work from the collection',
  upload: 'Upload',
  remove: 'Delete',
  security_name: 'Security',
  required: 'Required',
  recommended: 'Recommended',
  minimum_password: 'Password length is at least 6 characters',
  eq_password: 'Passwords match',
  lowercase: 'Presence of lowercase characters',
  uppercase: 'Presence of uppercase characters',
  special_characters: 'Presence of numbers and special characters',
  main_email: 'Main email',
  accept_email: 'To apply a new email {{email }}, activate it by the link sent to it',
  change_email: 'When changing email, you will receive an activation link to your email',
  email_placeholder: 'Your email',
  change_password: 'Password change',
  old_password: 'Old password',
  old_password_placeholder: 'Enter current password',
  new_password: 'New password',
  new_password_placeholder: 'Enter new password',
  confirm_password: 'Confirm password',
  confirm_password_placeholder: 'Repeat the new password',
  remove_account: 'Account deletion',
  remove_account_info: 'I am notified that when deleting an account, all settings will be reset; messages, works and other content, as well as site services will become unavailable',
  remove_account_review: 'Your request to delete your account will be reviewed within {{days }} {{ declination }}',
  remove_reset: 'Cancel account deletion',
  remove_action: 'Delete my account forever',
  wrong_password: 'Wrong password',
  eq_error_password: 'Passwords must match',
  save: 'Save',
  await: 'Wait...',
  forgot_password: 'Forgot password?',
  password_changed: 'Password changed successfully!',
  email_renew: 'To change email, confirm it using link in the email',
  collection_saved: 'Information about collection has been successfully saved',
  verification: 'Submit for verification',
  verification_loading: 'Wait, we are sending',
  real_attention: 'Attention!',
  real_question: 'For verification, you can submit an account with real and most complete data about yourself. \n\n aRe you sure that everything meets the requirements?',
  sent_success: 'The request has been sent successfully, wait for our manager to contact you',
  auto_renewal_promotion: 'Placement of artwork "{{ name }}" in the market for {{ price }} {{ currency }} / month',
  auto_renewal_tariff: 'Tariff "{{ name }}" for {{ price }} {{ currency }}',
  auto_renewal_expired: 'Renewal {{ time }}',
  status: 'Your status',
  status_info: 'Status, a short message to everyone, will be displayed on your personal page',
  status_placeholder: 'Enter your status',
};
