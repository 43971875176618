/* eslint-disable max-len */
export default {
  error_types: 'There are files that are not supported. Valid extensions {{ types }}',
  error_size: 'There are files that exceed the allowed size {{ size }}',
  total_size: 'Total size {{ size }}',
  clear_all: 'Clear all',
  attach_files: 'Attach files',
  attach_help: 'Use the button or drag files to this area',
  loading: 'Wait, loading {{ process }}%',
};
