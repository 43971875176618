/* eslint-disable max-len */
export default {
  select_status: 'Select status',
  search_sort_name: 'Sorting and searching',
  sort: 'Sort by',
  filter: 'Filter',
  select_institution: 'Select an institution type',
  select_technique: 'Select art media',
  select_type_exhibition: 'Select a type of event',
  select_location: 'Select a location',
  select_album: 'Select an album',
  select_artist: 'Start input artist',
  reset: 'Reset filters',
  by_time: 'Time',
  by_start_time: 'Start time',
  by_end_time: 'End time',
  by_name: 'Name',
  by_artist: 'Artist',
  search_from: 'Search among {{ count }}',
  by_online: 'Type',
  by_notes: 'Notes',
  select_artworks: 'Select artwork for exhibition',
  select_process: 'Artwork selection mode',
  select_online: 'Select variant',
  select_actual: 'Select relevance',
  no_request_result: 'Nothing is found at your request "{{ request }}" ',
  no_filter_result: "Sorry, we couldn't find any matches for that",
  no_request_filter_result: "Sorry, we couldn't find any matches for \"{{ request }}\"",
  select_education: 'Lecture option',
  tags_education: 'Lecture tags',
  market: 'Market',
  collection: 'Online-museum',
  wrap: 'Collapse',
  unwrap: 'Expand',
  category: 'By format/category',
  small_size: 'Small',
  small_size_help: 'Up to {{ size }} cm',
  medium_size: 'Medium',
  medium_size_help: '{{ from }} - {{ to }} cm',
  big_size: 'Large',
  big_size_help: 'From {{ size }} cm',
  loading: 'Loading...',
  not_found: 'Not found',
};
