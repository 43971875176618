/* eslint-disable no-return-assign */
import moment from 'moment/moment';
import _ from 'lodash';

export const REMOVE_ONE = 1;
export const REMOVE_ALL = 2;
export const CONFIRM_ALL = 3;

export const EXHIBITION_TYPES = [
  { label: 'Personal', value: 1 },
  { label: 'Group', value: 2 },
];

export const EXHIBITION_ONLINE = [
  { label: 'Offline', value: false },
  { label: 'Online', value: true },
];

export const EXHIBITION_ACTUAL = [
  { label: 'All', value: false },
  { label: 'Actual', value: true },
];

export const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const getExhibitionType = (type) => {
  if (type) {
    const result = EXHIBITION_TYPES.filter((v) => (v.value === type));
    return _.get(result, '[0]', null) ? result[0].label : '';
  }
  return '';
};

export const getWorkDays = (days) => {
  if (!_.isEmpty(days)) {
    const selected = [];
    days.map((i) => selected[i] = DAYS[i]);
    return selected.filter((i) => i !== null).sort((a, b) => a - b).join(', ');
  }
  return '';
};

export const getWorkDate = (dates) => {
  if (dates[0]) {
    return `${moment.unix(+dates[0]).format('LL')}${dates.length > 1 ? moment.unix(+dates[1]).format(' - LL') : ''}`;
  }
  return '';
};

export const getWorkTime = (workTime) => {
  if (workTime[0]) {
    return `${moment.unix(workTime[0]).format('HH:mm')} - ${moment.unix(workTime[1]).format('HH:mm')}`;
  }
  return '';
};
