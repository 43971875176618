/* eslint-disable max-len */
export default {
  required: 'Required field',
  text_num_space: 'Only text, numbers and spaces',
  email_format: 'Check the email format',
  minimum_length: 'The minimum length is {{ count }} characters',
  maximum_length: 'The maximum length is {{ count }} characters',
  need_select: "It's necessary to choose",
  only_text: 'Text only',
  only_url: 'URL only',
  only_digits: 'Digits only',
  only_number: 'Only number',
  only_latin_digits: 'Only Latin alphabet characters, digits and _',
  email: 'Email must contain @ and .',
  required_fields: 'Fields are required',
  currency_type: 'Specify the type of currency',
  date: 'Correct formats: 01.07.1999, 07.1999 or 1999, via / or -',
  phone: 'Not correct number phone (example: +36500000000)',
  phone_required: 'Phone required',
  max_count: 'Possible to attach only {{ count }} files at one time!',
  required_check: 'Required to select',
};
