import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Container } from 'react-bootstrap';
import _ from 'lodash';
import { ga } from 'react-ga';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { GQL_GET_MENU, GQL_SEND_FEEDBACK } from '../graphql/queries';
import { useTranslation } from './hooks/useTranslation';
import Icon from './Icon';
import FeedbackModal from './FeedbackModal';

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  const { t } = useTranslation();
  const alert = useAlert();

  const [contact, setContact] = useState(false);
  const [menus1, setMenu1] = useState([]);
  const [menus2, setMenu2] = useState([]);

  const [sendFeedback] = useMutation(GQL_SEND_FEEDBACK);

  const [loadSetting1, { data: menuData1 }] = useLazyQuery(GQL_GET_MENU, {
    variables: { code: 'footer_menu' },
  });

  const [loadSetting2, { data: menuData2 }] = useLazyQuery(GQL_GET_MENU, {
    variables: { code: 'footer_menu_2' },
  });

  useEffect(() => {
    loadSetting1();
    loadSetting2();
    return () => {
      setContact(false);
    };
  }, []);

  useEffect(() => {
    if (_.get(menuData1, 'menu.children')) {
      setMenu1(_.get(menuData1, 'menu.children', []));
    }
    if (_.get(menuData2, 'menu.children')) {
      setMenu2(_.get(menuData2, 'menu.children', []));
    }
    return () => {
      setMenu1([]);
      setMenu2([]);
    };
  }, [_.get(menuData1, 'menu.children'), _.get(menuData2, 'menu.children')]);

  const contactSubmit = async (values, { setSubmitting, setErrors }) => {
    const response = await sendFeedback({
      variables: {
        data: _.pick(values, ['name', 'email', 'phone', 'description']),
      },
    });
    const errors = _.get(response, 'errors[0]', {});
    if (_.isEmpty(errors)) {
      alert.show(t('message_send_success', { ns: 'alerts' }), { type: 'success' });
      setContact(false);
    } else {
      alert.show(errors.message, { type: 'danger' });
      setSubmitting(false);
      setErrors(errors);
    }
  };

  const metrica = () => {
    setContact(true);
    ga('contacts', 'feedback', '');
  };

  return (
    <FooterBlock id="footer">
      <Container>
        <TopFooter>
          <img alt="logo" width="108" src="/images/wl.svg" />
          <Menu>
            {!_.isEmpty(menus1)
              && menus1.map((item) => (
                <Link key={`item-${item.url}`} to={item.url}>
                  {item.name}
                </Link>
              ))}
          </Menu>
          <Social>
            <A target="_blank" rel="noopener noreferrer" href="https://t.me/artocratiacom">
              <Icon icon="tg" height={20} width={22} colorFill="#ffffff" noHover />
            </A>
            <A
              target="_blank"
              className="mt-1"
              rel="noopener noreferrer"
              href="https://vk.com/artocratia"
            >
              <Icon icon="vk" height={27} width={27} colorFill="#ffffff" noHover />
            </A>
          </Social>
        </TopFooter>
        <Bottom>
          <div>{`© ${year} ARTOCRATIA`}</div>
          <Menu>
            {!_.isEmpty(menus2)
              && menus2.map((item) => (
                <Link key={`item-${item.url}`} to={item.url}>
                  {item.name}
                </Link>
              ))}
            <Btn type="button" onClick={metrica}>{t('contact', { ns: 'main' })}</Btn>
          </Menu>
          <div>{t('all_rights', { ns: 'main' })}</div>
        </Bottom>
      </Container>
      <FeedbackModal
        t={t}
        show={contact}
        onHide={() => setContact(false)}
        onSubmit={contactSubmit}
      />
    </FooterBlock>
  );
};

const A = styled.a`
  display: inline-block;
  &:hover {
    svg {
      path {
        fill: var(--art-rose);
      }
    }
  }
`;

const Btn = styled.button`
  padding: 0;
  margin: 0;
  font-size: 0.85rem;
  text-transform: uppercase;
  color: white;
  line-height: 1;
  border: 0;
  margin-left: 14px;
  vertical-align: inherit;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  @media screen and (max-width: 786px) {
    text-transform: capitalize;
    margin-left: 0;
    margin-bottom: 12px;
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  a {
    padding: 0 0.9rem;
    text-transform: uppercase;
    font-size: 0.85rem;
  }
  @media (max-width: 786px) {
    a {
      padding: 0 2rem;
    }
  }
`;

const Menu = styled.div`
  a {
    padding: 0 1rem;
    text-transform: uppercase;
    font-size: 0.85rem;
  }
  @media (max-width: 786px) {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    a {
      margin-bottom: 1rem;
      text-transform: capitalize;
    }
  }
`;

const TopFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85rem;
  padding: 3rem 0;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Bottom = styled.div`
  border-top: 1px solid rgba(237, 237, 237, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85rem;
  padding: 3rem 0;
  color: white;
  div {
    color: white;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const FooterBlock = styled.footer`
  background: #050436;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  a {
    color: #ffffff;
  }
`;

export default Footer;
