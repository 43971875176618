/* eslint-disable max-len */
export default {
  title: '{{ name }} - {{ role }} on Artocratia{{ path }}',
  description: '{{ name }} - {{ role }}{{ path }} | User of the social network for connoisseurs of art Artocratia',
  profile_label: 'Profile data',
  profile_companies: 'Companies',
  share_label: 'Share the user card',
  share_description: 'User card {{ name }} of social network for art lovers - Artocratia',
  go_to_collection: "GO to user's page",
  wright_message: 'Message',
  about: 'About',
  contacts: 'Contacts',
  contacts_placeholder: 'Enter a contact',
  add_contact: 'Add a contact',
  cant_contact: 'User has closed the ability to communicate',
  your_card: 'This is your card',
  as_artist: 'The user represents the artist',
  verified: 'Account confirmed',
  can_bye: 'Possible to buy',
  in_collections: 'In collection',
  art_all: 'All artworks',
  friends: 'Friends',
  subscribers: 'Subscribers',
  in_favorites: 'In favorites',
  in_favorite: 'To favorites',
  my_favorites: 'My favorites',
  my_locations: 'My locations',
  more: 'More',
  share: 'Share',
  block: 'Block',
  abuse: 'Complain',
  only_register: 'Viewing for registered users only',
  edit: 'Edit',
  information: 'information',
  look_like_user: 'View as user',
  look_like_guest: 'View as guest',
  back_edit: 'Back to editing',
  posts: 'Publications',
  events: 'Events',
  about_him: 'About the user',
  about_info: 'Info',
  field_collection_info: 'Fill in collection information',
  show_all: 'Show in full',
  user_empty: 'No info',
  was: 'Last seen',
  online: 'Online',
};
