/* eslint-disable max-len */
export default {
  status: 'Status',
  link: 'General link',
  in_side: '{{ count }} {{ name }} in album ',
  publish_name: 'album',
  sure_delete: 'Are you sure you want to delete the album {{ name }}?',
  recovery_impossible: 'Recovering will not be possible',
  empty_text: "You haven't created any albums yet",
  empty_create: 'Would you like to create?',
  empty_add: 'Add an album',
  my: 'My albums',
  title: 'OFFICE - MY ALBUMS',
  name: 'My Albums',
  add: 'Add an album',
  no_items: 'Nothing is found',
  no_request_result: 'By your request "{{ request }}" nothing is found',
  no_filter_result: "Sorry, we couldn't find any matches for that",
  no_request_filter_result: "Sorry, we couldn't find any matches for \"{{ request }}\"",
  search_placeholder: 'Input name',
};
