/* eslint-disable max-len */
export default {
  name: 'Link generator',
  list_1: 'This link opens your unpublished album for anyone who has the link',
  list_2: 'If the album is published for everyone including guests the link will be deleted',
  list_3: 'After generating new links access to old ones is cancelled',
  list_4: 'After deleting links access is cancelled',
  break: 'Link is not available',
  by_link: 'Access to link',
  remove: 'Remove link',
};
