/* eslint-disable max-len */
export default {
  name_feedback: 'Feedback form',
  input_name: 'Enter a name',
  input_email: 'Enter email',
  name: 'Name',
  text: 'Text',
  input_feedback: 'Enter your feedback',
  phone: 'Phone number',
  required_field: 'Required',
  error_number_format: 'Invalid number format',
  error_email_format: 'Invalid email format',
  input_format_phone: 'Enter a phone in the format {{ phone }}',
  revert_image: 'Expand the photo',
  select_date: 'Select the date or period',
  start: 'Beginning',
  end: 'Ending',
  date: 'Date',
  time_from: 'Time from',
  time_to: 'Time to',
};
