/* eslint-disable no-restricted-syntax */
import _ from 'lodash';

import {
  ARTWORK_SECTION, CURRENCY_DOL, CURRENCY_EUR,
} from './main';

export const ARTWORK_PRICE = 1;

export const SYSTEM_STRIPE = 'stripe';
export const SYSTEM_SELF = 'self';

export const PAYMENT_WAITING = 'waiting_for_capture';
export const PAYMENT_PENDING = 'pending';
export const PAYMENT_SUCCEEDED = 'succeeded';
export const PAYMENT_CANCELED = 'canceled';
export const PAYMENT_ERROR = 'error';

export const MODE_MONTH = 'month';
export const MODE_YEAR = 'year';
export const MODE_SINGLE = 'single_pay';
export const MODE_NORMAL = 'normal';
export const MODE_MODULES = 'modules';

export const PERIOD_TYPE = 'period';
export const EDUCATION_TYPE = 'education';

export const FULL_DISCOUNT = 'full_discount';

export const EXTEND_PAYMENT = 'extend_payment';
export const NEXT_PAYMENT = 'next_payment';
export const FULL_PAYMENT = 'early_full_repayment';
export const ONE_TIME_PAYMENT = 'one_time_payment';

export const financial = (rawPrice) => (Number.parseFloat(rawPrice).toFixed(2) * 100) / 100;

export const getCurrencyName = (currency) => {
  const currencyEurLang = 'euro';
  const currencyDolLang = 'dollar';
  return currency === CURRENCY_EUR ? currencyEurLang : currencyDolLang;
};

export const getCurrencyIcon = (currency) => {
  const currencyEur = '€';
  const currencyDol = '$';
  switch (currency) {
    case CURRENCY_DOL:
      return currencyDol;
    default:
      return currencyEur;
  }
};

export const isTariffPaid = (payments, setIsPaid) => {
  let isPaid = false;
  if (!_.isEmpty(payments)) {
    for (const payment of payments) {
      if (_.get(payment, 'items') && !_.isEmpty(payment.items)) {
        for (const pay of payment.items) {
          if (pay.model !== ARTWORK_SECTION) {
            const unixTimeNow = Math.floor(Date.now() / 1000);
            if (pay.expired > unixTimeNow) {
              isPaid = pay;
              break;
            }
          }
        }
      }
    }
  }
  setIsPaid(isPaid);
};
