/* eslint-disable max-len */
export default {
  name: 'Status',
  for_friends: 'Published for friends',
  for_market_friends: 'Published for friends at market',
  published: 'Published for everyone',
  published_only: 'Published for everyone',
  published_market: 'Published for everyone at market',
  unpublish_info: 'Click to unpublish the publication',
  publish_info: 'Click to publish',
  publish_not: "You can't publish due to the lack of necessary information: {{ info }}",
  no_images: 'no photos',
  no_artworks: 'no artworks',
  moderation: 'Under moderation',
  moderation_info: 'Editing and unpublishing will be available after moderation',
  edit: 'Only I can see',
  not_paid: 'Not paid. Artwork is visible only to you',
  not_paid_info: 'For visible artwork in market, it is necessary to purchase a new or extend an existing tariff',
};
