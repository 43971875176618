/* eslint-disable max-len */
export default {
  picker: {
    search: 'Search',
    clear: 'Clear',
    notfound: 'Nothing found',
    skintext: 'Choose a default color',
    categories: {
      search: 'Search results',
      recent: 'Frequently used',
      smileys: 'Smilies',
      people: 'Smilies',
      nature: 'Animals and nature',
      foods: 'Food and drink',
      activity: 'Activities',
      places: 'Travel and Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Custom',
    },
    categorieslabel: 'Categories',
    skintones: {
      1: 'Standard',
      2: 'Light',
      3: 'Semi-light',
      4: 'Medium',
      5: 'Semi-dark',
      6: 'Dark',
    },
  },
};
