/* eslint-disable max-len */
export default {
  no_items: 'Nothing is found',
  placeholder_search: 'Search among {{ total }}',
  no_request_result: 'Nothing is found at your request "{{ request }}"',
  no_filter_result: "Sorry, we couldn't find any matches for that",
  no_request_filter_result: "Sorry, we couldn't find any matches for \"{{ request }}\"",
  sure_delete: 'Are you sure you want to delete the institution {{ name }}?',
  recovery_impossible: 'Recovering will not be possible',
  published: 'institution',
  empty_text: "You haven't created any institutions yet",
  empty_create: 'Would you like to create?',
  empty_add: 'Add an institution',
  my: 'My institutions',
  title: 'My account - My institutions',
  my_label: 'My museums and galleries',
  create: 'Create an institution',
  search_placeholder: 'Enter a name',
  close_add: 'Publish exhibitions using the <em>{{ name }}</em> subscription to Artocratia',
  hidden_tariff: 'Explore tariffs',
};
