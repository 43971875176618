/* eslint-disable import/no-import-module-exports */
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { loadableReady } from '@loadable/component';

import { createApolloClient } from './graphql/client';

import App from './App';
import './locales';

const ClientApp = () => {
  const client = createApolloClient();
  return (
    <BrowserRouter>
      <App client={client} />
    </BrowserRouter>
  );
};

loadableReady(() => {
  const renderMethod = module.hot ? render : hydrate;
  renderMethod(<ClientApp />, document.getElementById('app'));
});

if (module.hot) {
  module.hot.accept();
}
