/* eslint-disable max-len */
export default {
  name: 'Unit',
  figure: 'Form of artwork',
  wh: 'HW',
  diameter: 'D',
  height: 'H',
  width: 'W',
  depth: 'G',
  short: 'U',
  type: 'Select type',
  free: 'Free Size',
  add_type: 'Add a size type',
  flexible: 'Varies',
  disabled: 'Without a size',
};
