import { useMutation, useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { ga } from 'react-ga';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import config from '../config';
import {
  GQL_GET_ME, GQL_GET_MENU, GQL_GET_SETTING, GQL_UPDATE_EXHIBITION, GQL_UPDATE_INSTITUTION,
} from '../graphql/queries';
import {
  cutText, EXHIBITION_SECTION, INSTITUTION_SECTION, parseTextLinks,
} from '../helpers/main';
import useClickOutside from './hooks/useClickOutside';
import { useTranslation } from './hooks/useTranslation';

const MessagesCount = loadable(() => import('./MessagesCount'), { ssr: false });
const NotificationCount = loadable(() => import('./NotificationCount'), { ssr: false });
const CreateModal = loadable(() => import('./artworks/createModal'), { ssr: false });
const NotificationModal = loadable(() => import('./NotificationModal'), { ssr: false });
const Icon = loadable(() => import('./Icon'), { ssr: false });
const SearchForm = loadable(() => import('./SearchForm'), { ssr: false });
const TariffModal = loadable(() => import('./TariffModal'), { ssr: false });

const TopMenu = () => {
  const refDropdownAccount = useRef();
  const refDropdownActions = useRef();
  const refMainMenu = useRef();

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [notificationModal, setNotificationModal] = useState({ show: false, setting: '' });
  const [dropDownAccount, setDropDownAccount] = useState(false);
  const [dropDownActions, setDropDownActions] = useState(false);
  const [startCreate, setCreate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [processSearch, setProcessSearch] = useState(null);
  const [tariffModal, setTariffModal] = useState(false);

  useClickOutside(refDropdownAccount, () => setDropDownAccount(false));
  useClickOutside(refDropdownActions, () => setDropDownActions(false));
  useClickOutside(refMainMenu, () => setVisible(false));

  const [addExhibition, { data: exhibitionData }] = useMutation(GQL_UPDATE_EXHIBITION);
  const [addInstitution, { data: institutionData }] = useMutation(GQL_UPDATE_INSTITUTION);

  const { data: dataMe, loading } = useQuery(GQL_GET_ME, { fetchPolicy: 'cache-only' });

  const { data: notificationData } = useQuery(GQL_GET_SETTING, {
    fetchPolicy: 'network-only',
    variables: {
      code: 'notification',
    },
  });

  const { data: menuData } = useQuery(GQL_GET_MENU, {
    variables: {
      code: 'header',
      location:
        _.get(location, 'pathname', '') === '/'
          ? '/'
          : _.get(location, 'pathname', '').split('/')[1],
    },
    fetchPolicy: 'cache-and-network',
  });

  const me = _.get(dataMe, 'me', {});

  useEffect(() => {
    let timeout;
    if (!location.pathname.includes('invitation')) {
      timeout = setTimeout(() => {
        if (me && me._id && me.signed_up === 'unregister') {
          const setting = _.get(notificationData, 'setting', false);
          if (setting) {
            setNotificationModal({
              show: true,
              setting: {
                ...setting,
                phone: _.get(me, 'phone'),
              },
            });
          }
        }
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      setNotificationModal(false);
    };
  }, [notificationData]);

  useEffect(() => {
    if (_.get(exhibitionData, 'updateExhibition._id')) {
      history.push(`/account/exhibitions/${_.get(exhibitionData, 'updateExhibition._id')}`);
    }
    if (_.get(institutionData, 'updateInstitution._id')) {
      history.push(`/account/institutions/${_.get(institutionData, 'updateInstitution._id')}`);
    }
  }, [exhibitionData, institutionData]);

  useEffect(() => {
    if (!loading && localStorage.getItem('openCreate')) {
      setTimeout(() => setCreate(true), 500);
    }
  }, [me, loading]);

  const menu = _.get(menuData, 'menu', {});

  const menus = _.get(menuData, 'menu.children', []);

  const userPath = `/@${_.get(me, 'username') || _.get(me, '_id')}`;

  const hideModal = () => {
    setNotificationModal({ show: false, setting: '', wasSend: true });
  };

  const logOut = async () => {
    window.location.href = `${config.api}auth/logout`;
  };

  const metrica = (target) => {
    ga(target, 'click_top', '');
  };

  const add = (section) => {
    switch (section) {
      case EXHIBITION_SECTION:
        if (+_.get(me, 'tariff.priority', 0) !== 1) {
          setTariffModal('exhibition');
          return false;
        }
        addExhibition();
        break;
      case INSTITUTION_SECTION:
        if (+_.get(me, 'tariff.priority', 0) !== 1) {
          setTariffModal('institutions');
          return false;
        }
        addInstitution();
        break;
      default: break;
    }
    return true;
  };

  const openSearch = () => {
    setProcessSearch(!processSearch);
    setVisible(false);
  };

  const renderMainMenu = () => {
    if (_.isEmpty(menus)) {
      return '';
    }
    let i = 0;
    return (
      <MainMenu ref={refMainMenu} active={_.get(me, '_id', false) ? 1 : undefined}>
        <MobileMenuButton
          visible={visible ? 1 : undefined}
          onClick={() => setVisible(!visible)}
          type="button"
        >
          <Line visible={visible ? 1 : undefined} />
        </MobileMenuButton>
        <Ul active={visible ? 1 : undefined}>
          <SearchMobile>
            <SearchButton type="button" onClick={openSearch}>
              <Icon
                icon="search"
                className="search"
                colorFill={css`var(--art-default-extra)`}
                width={17}
                height={17}
                colorStroke={css`var(--art-default-extra)`}
              />
              {t('search', { ns: 'main' })}
            </SearchButton>
          </SearchMobile>
          {!_.get(me, '_id', false) && (
            <TariffMobileGuest>
              <ActionLink
                itemProp="url"
                to="/tariffs"
                onClick={() => metrica('tariff_top')}
                active={location && location.pathname.includes('/tariffs') ? 1 : undefined}
              >
                <Icon
                  icon="layouts"
                  width={16}
                  height={16}
                  colorStroke={css`var(--art-default-extra)`}
                  noHover
                />
                {t('menu_tariffs', { ns: 'main' })}
              </ActionLink>
            </TariffMobileGuest>
          )}
          {menus.map((item) => {
            i += 1;
            return (
              <li key={`item-${i}`}>
                <Item
                  itemProp="url"
                  to={item.url}
                  active={
                    location
                    && location.pathname === item.url
                    && !location.pathname.includes('account')
                      ? 1
                      : undefined
                  }
                  onClick={() => metrica('top_menu')}
                >
                  <IconMobile
                    icon={item.class}
                    width={16}
                    height={16}
                    colorFill="transparent"
                    colorStroke={css`var(--art-default-extra)`}
                    noHover
                  />
                  {item.name}
                </Item>
              </li>
            );
          })}
        </Ul>
      </MainMenu>
    );
  };

  const renderGuestMenu = () => (
    <SideArea>
      <Buttons>
        <StyleBtnLink
          itemProp="url"
          to="/tariffs"
          onClick={() => metrica('tariff_top')}
          active={location && location.pathname.includes('/tariffs') ? 1 : undefined}
        >
          {t('menu_tariffs', { ns: 'main' })}
        </StyleBtnLink>
        <NavLink
          itemProp="url"
          onClick={() => metrica('reg_top')}
          to="/login"
        >
          <LoginIcon icon="login" width={12} height={12} colorStroke="#FFFFFF" noHover />
          {t('login', { ns: 'main' })}
        </NavLink>
      </Buttons>
    </SideArea>
  );

  const renderUserMenu = () => (
    <SideArea>
      <StyleIconLink
        active={location && location.pathname.includes('account/messages') ? 1 : undefined}
        to="/account/messages"
      >
        <Icon
          icon="message"
          width={22}
          height={22}
          colorFill="#000000"
          colorStroke="#000000"
          noHover
        />
        <MessagesCount userId={me._id} />
      </StyleIconLink>
      <StyleIconLinkNotification
        active={location && location.pathname.includes('account/notifications') ? 1 : undefined}
        to="/account/notifications"
      >
        <Icon
          icon="notification"
          width={22}
          height={22}
          colorFill="#000000"
          colorStroke="#000000"
          noHover
        />
        <NotificationCount userId={me._id} />
      </StyleIconLinkNotification>
      <DropArea ref={refDropdownAccount}>
        <Dropdown
          type="button"
          active={dropDownAccount ? 1 : undefined}
          onClick={() => setDropDownAccount(!dropDownAccount)}
        >
          <Avatar
            width="555"
            height="555"
            alt={_.get(me, 'name')}
            src={
              _.get(me, 'avatar.key', null) ? `/files/${me.avatar.key}` : '/images/no-avatar.jpg'
            }
          />
        </Dropdown>
        <DropdownUserMenu active={dropDownAccount ? 1 : undefined}>
          <li>
            <ActionLinkUser
              to={userPath}
              active={location.pathname === userPath ? 1 : undefined}
            >
              <AvatarMenu
                width="555"
                height="555"
                alt={_.get(me, 'name')}
                src={
                  _.get(me, 'avatar.key', null) ? `/files/${me.avatar.key}` : '/images/no-avatar.jpg'
                }
              />
              <div>
                {cutText(_.get(me, 'name', 'Not set'), 20)}
                <Account>{t('your_account', { ns: 'main' })}</Account>
              </div>
            </ActionLinkUser>
          </li>
          <li>
            <ActionLink
              itemProp="url"
              to="/tariffs"
              onClick={() => metrica('tariff_top')}
              active={location && location.pathname.includes('/tariffs') ? 1 : undefined}
            >
              <Icon
                icon="layouts"
                width={16}
                height={16}
                colorStroke={css`var(--art-default-extra)`}
                noHover
              />
              {t('menu_tariffs', { ns: 'main' })}
            </ActionLink>
          </li>
          <li>
            <ActionLink
              to="/account/users"
              active={location && location.pathname.includes('account/users') ? 1 : undefined}
            >
              <Icon
                icon="users"
                width={16}
                height={16}
                colorStroke={css`var(--art-default-extra)`}
                noHover
              />
              {t('menu_users', { ns: 'main' })}
            </ActionLink>
          </li>
          <li>
            <ActionLink
              to="/account/artworks"
              active={location && location.pathname.includes('/account/artworks') ? 1 : undefined}
            >
              <Icon
                icon="picture"
                width={16}
                height={16}
                colorStroke={css`var(--art-default-extra)`}
                noHover
              />
              {t('menu_my_artworks', { ns: 'main' })}
            </ActionLink>
          </li>
          <li>
            <ActionLink
              to="/account/institutions"
              active={location && location.pathname.includes('/account/institutions') ? 1 : undefined}
            >
              <Icon
                icon="museum"
                width={16}
                height={16}
                colorStroke={css`var(--art-default-extra)`}
                noHover
              />
              {t('menu_museums', { ns: 'main' })}
            </ActionLink>
          </li>
          <li>
            <ActionLink
              to="/account/exhibitions"
              active={location && location.pathname.includes('/account/exhibitions') ? 1 : undefined}
            >
              <Icon
                icon="exhibition"
                width={16}
                height={16}
                colorStroke={css`var(--art-default-extra)`}
                noHover
              />
              {t('menu_my_exhibitions', { ns: 'main' })}
            </ActionLink>
          </li>
          <li>
            <ActionLink
              to="/account/educations"
              active={location && location.pathname.includes('/account/educations') ? 1 : undefined}
            >
              <Icon
                icon="educationHead"
                width={19}
                height={19}
                colorStroke={css`var(--art-default-extra)`}
                noHover
              />
              {t('menu_educations', { ns: 'main' })}
            </ActionLink>
          </li>
          <li>
            <ActionLink
              active={location && location.pathname.includes('account/notifications') ? 1 : undefined}
              to="/account/notifications"
            >
              <Icon
                icon="notification"
                width={16}
                height={16}
                colorFill={css`var(--art-default-extra)`}
                colorStroke={css`var(--art-default-extra)`}
                noHover
              />
              {t('menu_notifications', { ns: 'main' })}
              <NotificationCount userId={me._id} />
            </ActionLink>
          </li>
          <li>
            <ActionLink
              key={3}
              to="/account/my-info"
              active={location
              && (
                location.pathname.includes('account/my-info')
                || location.pathname.includes('account/my-collection')
                || location.pathname.includes('account/my-security')
                || location.pathname.includes('account/my-notifications')
                || location.pathname.includes('account/my-payments')
              ) ? 1 : undefined}
            >
              <Icon
                icon="settings"
                width={16}
                height={16}
                colorFill="transparent"
                colorStroke={css`var(--art-default-extra)`}
                noHover
              />
              {t('menu_settings', { ns: 'main' })}
            </ActionLink>
          </li>
          <li>
            <ActionButton key={5} type="button" onClick={logOut}>
              <Icon
                icon="logout"
                width={16}
                height={16}
                colorStroke={css`var(--art-default-extra)`}
                noHover
              />
              {t('logout', { ns: 'main' })}
            </ActionButton>
          </li>
        </DropdownUserMenu>
      </DropArea>
    </SideArea>
  );

  return (
    <>
      <TopArea>
        <Menu
          itemScope
          itemType="http://www.schema.org/SiteNavigationElement"
        >
          <Area>
            <LinkLogo to="/">
              <Logo
                width="125"
                height="32"
                src="/images/logo.svg"
                alt="Artocratia"
              />
            </LinkLogo>
            <Actions active={_.get(me, '_id', false) ? 1 : undefined}>
              {renderMainMenu()}
              <SearchArea>
                <SearchForm visible openSearch={processSearch} />
              </SearchArea>
              {_.get(me, '_id', false) && (
                <Buttons active={_.has(me, 'tariff.priority') ? 1 : undefined}>
                  <DropArea ref={refDropdownActions}>
                    <DropdownButton
                      type="button"
                      active={dropDownActions ? 1 : undefined}
                      onClick={() => setDropDownActions(!dropDownActions)}
                    >
                      <Plus active={dropDownActions ? 1 : undefined} />
                      <Hidden>{t('publish', { ns: 'main' })}</Hidden>
                    </DropdownButton>
                    <DropdownActions active={dropDownActions ? 1 : undefined}>
                      <ActionButton type="button" onClick={() => setCreate(true)}>
                        <Icon
                          icon="picture"
                          width={16}
                          height={16}
                          colorStroke={css`var(--art-default-extra)`}
                          noHover
                        />
                        {t('button_add_artwork', { ns: 'main' })}
                      </ActionButton>
                      <ActionLink
                        to={{
                          pathname: `/@${_.get(me, 'username') || _.get(me, '_id')}`,
                          state: { scrollToNow: 'post' },
                        }}
                      >
                        <Icon
                          icon="edit"
                          width={16}
                          height={16}
                          colorStroke={css`var(--art-default-extra)`}
                          noHover
                        />
                        {t('button_add_post', { ns: 'main' })}
                      </ActionLink>
                      <ActionButton type="button" onClick={() => add(EXHIBITION_SECTION)}>
                        <SmallPlus />
                        {t('button_add_exhibition', { ns: 'main' })}
                      </ActionButton>
                      <ActionButton type="button" onClick={() => add(INSTITUTION_SECTION)}>
                        <SmallPlus />
                        {t('button_add_gallery', { ns: 'main' })}
                      </ActionButton>
                    </DropdownActions>
                  </DropArea>
                  {!_.has(me, 'tariff.priority') && (
                    <StyleBtnLink
                      to="/tariffs"
                      onClick={() => metrica('tariff_top')}
                      active={location && location.pathname.includes('/tariffs') ? 1 : undefined}
                    >
                      {t('menu_subscribe', { ns: 'main' })}
                    </StyleBtnLink>
                  )}
                </Buttons>
              )}
            </Actions>
            {_.get(me, '_id', false) ? renderUserMenu() : renderGuestMenu()}
          </Area>
          {startCreate && <CreateModal onHide={() => setCreate(false)} show={startCreate} />}
          {notificationModal && (
            <NotificationModal
              show={notificationModal.show}
              setting={notificationModal.setting}
              onHide={hideModal}
              refetchQueries={[{ query: GQL_GET_ME }]}
            />
          )}
        </Menu>
      </TopArea>
      {tariffModal && (
        <TariffModal
          show={!!tariffModal}
          onHide={() => setTariffModal(false)}
          ns={tariffModal}
        />
      )}
      {_.has(menu, 'top_text') && _.get(menu, 'top_text') && (
        <AsideInfo>
          <Content>
            <div>{parseTextLinks(_.get(menu, 'top_text'))}</div>
            <Icon icon="arrowOut" noHover width="10" height="10" colorFill="white" />
          </Content>
        </AsideInfo>
      )}
    </>
  );
};

const SearchMobile = styled.li`
  display: none;
  @media screen and (max-width: 500px) {
    display: block;
  }
`;

const TariffMobileGuest = styled.li`
  display: none;
  @media screen and (max-width: 500px) {
    display: block;
  }
`;

const SearchButton = styled.div`
  margin: 0;
  color: var(--art-dark);
  background: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding: 9px;
  svg {
    margin-right: 20px;
    margin-top: -3px;
  }
`;

const Actions = styled.div`
  order: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 1000px) {
    order: 1;
    width: auto;
    min-width: 160px;
    padding-right: 40px;
    justify-content: ${({ active }) => (active ? 'space-between' : 'flex-start')};
  }
  @media screen and (max-width: 500px) {
    min-width: 80px;
    padding-right: 0;
  }
`;

const MainMenu = styled.div`
  order: 1;
  flex: 1;
  @media screen and (max-width: 1000px) {
    margin-right: ${({ active }) => (active ? 0 : 20)}px;
  }
  @media screen and (max-width: 500px) {
    margin-right: ${({ active }) => (active ? 15 : 20)}px;
  }
`;

const Account = styled.div`
  color: #969696;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const SearchArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 75px;
  order: 2;
  @media screen and (max-width: 1000px) {
    order: 3;
    min-width: 50px;
  }
`;

const Buttons = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ active }) => (active ? '1fr' : '1fr 1fr')};
  grid-column-gap: 15px;
  order: 3;
  @media screen and (max-width: 1300px) {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 1000px) {
    order: 2;
    justify-content: center;
  }
`;

const Hidden = styled.div`
  @media screen and (max-width: 1300px) {
    display: none;
  }
`;

const TopArea = styled.div`
  min-height: 70px;
  @media screen and (max-width: 500px) {
    min-height: 50px;
  }
`;

const LinkLogo = styled(Link)`
  order: 1;
  @media screen and (max-width: 1000px) {
    order: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Menu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  background-color: white;
  box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  padding: 0 15px;
  @media screen and (max-width: 500px) {
    height: 50px;
    position: relative;
  }
`;

const Area = styled.div`
  max-width: 1620px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 125px;
  height: 32px;
  margin-right: 50px;
  @media screen and (max-width: 1600px) {
    margin-right: 25px;
  }
  @media screen and (max-width: 1400px) {
    margin-right: 15px;
  }
  @media screen and (max-width: 1300px) {
    width: 100px;
    height: 35px;
  }
  @media screen and (max-width: 1000px) {
    width: 140px;
    height: 25px;
  }
  @media screen and (max-width: 600px) {
    width: 100px;
    height: 35px;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  @media screen and (max-width: 1000px) {
    display: block;
    padding: 12px;
    border: 0;
    position: relative;
    margin-top: 0;
    transition: all 200ms;

    &::before,
    &::after {
      position: absolute;
      display: inline-block;
      border-radius: 4px;
      height: 2px;
      width: 20px;
      background: var(--art-default-dark);
      transition: all 200ms;
    }

    &::before {
      content: '';
      left: ${({ visible }) => (visible ? '-4' : '0')}px;
      top: ${({ visible }) => (visible ? '15' : '5')}px;
      transform: ${({ visible }) => (visible ? css`translateX(4px) translateY(-3px) rotate(45deg)` : css`translateZ(0)`)};
      ${({ visible }) => (visible ? css`transition: transform 200ms ease` : css`transform-origin: 0 50%`)};
    }

    &::after {
      content: '';
      left: ${({ visible }) => (visible ? '-4' : '0')}px;
      bottom: ${({ visible }) => (visible ? '12' : '3')}px;
      transform: ${({ visible }) => (visible ? css`translateX(4px) translateY(2px) rotate(-45deg)` : css`translateZ(0)`)};
      ${({ visible }) => (visible ? css`transition: transform 200ms ease` : css`transform-origin: 0 50%`)};
    }
  }
`;

const Line = styled.div`
  background: ${({ light }) => (light ? '#FFFFFF' : css`var(--art-default-dark)`)};
  opacity: ${({ visible }) => (visible ? 0 : 1)};
  height: 2px;
  width: ${({ visible }) => (visible ? 0 : 20)}px;
  transition: all 300ms linear;
  position: absolute;
  border-radius: 4px;
  left: 0;
`;

const dropMenuStyle = css`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  min-width: 260px;
  list-style: none;
  padding: 10px;
  z-index: 1000;
  right: 0;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
`;

const Ul = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1000px) {
    ${dropMenuStyle};
    transform: translate(10px, 20px);
    display: ${({ active }) => (active ? 'flex' : 'none')};
    flex-direction: column;
    align-items: flex-start;
    right: auto;
    left: 0;
    li {
      width: 100%;
      order: 0;
      a {
        display: flex;
        width: 100%;
        svg {
          margin-right: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    transform: translate(0, 12px);
  }
`;

const linkStyle = css`
  font-size: 16px;
  color: ${({ active }) => (active ? 'var(--art-active)' : 'var(--art-default-dark)')};
  position: relative;
  padding: 19px 12px;
  display: block;
`;

const Item = styled(Link)`
  ${linkStyle};
  border-bottom: 2px solid ${({ active }) => (active ? 'var(--art-active)' : 'transparent')};
  margin-bottom: -2px;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  @media screen and (max-width: 1500px) {
    font-size: 15px;
    padding: 19px 8px;
  }
  @media screen and (max-width: 1400px) {
    font-size: 14px;
    padding: 19px 6px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    padding: 5px 6px;
    border-bottom: 0;
    margin-bottom: 0;
    border-radius: 4px;
    color: var(--art-default-dark);
    background-color: ${({ active }) => (active ? css`var(--art-light)` : 'transparent')};
    &:hover,
    &:focus {
      text-decoration: none;
      background-color: ${({ active }) => (active ? css`var(--art-light)` : 'transparent')};
    }
  }
  @media screen and (max-width: 500px) {
    padding: 9px;
  }
`;

const StyleIconLink = styled(Link)`
  padding: 8px 10px;
  position: relative;
`;

const StyleIconLinkNotification = styled(Link)`
  padding: 8px 10px;
  position: relative;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const IconMobile = styled(Icon)`
  display: none;
  @media screen and (max-width: 1000px) {
    display: block;
  }
`;

const actionBtnStyle = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding: 9px;
  border-radius: 5px;
  width: 100%;
  color: var(--art-dark);
  background-color: ${({ active }) => (active ? css`var(--art-light)` : 'transparent')};
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  svg {
    margin-right: 10px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--art-dark);
    background-color: var(--art-light);
  }
`;

const ActionButton = styled.button`
  ${actionBtnStyle};
`;

const ActionLink = styled(Link)`
  ${actionBtnStyle};
  #notifications-counter {
    left: 10px;
    top: 0;
    position: relative;
  }
`;

const ActionLinkUser = styled(Link)`
  ${actionBtnStyle};
  margin-bottom: 20px;
`;

const AvatarMenu = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 50px;
  @media screen and (max-width: 1600px) {
    margin-left: 25px;
  }
  @media screen and (max-width: 1400px) {
    margin-left: 15px;
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 1070px) {
    width: 35px;
    height: 35px;
  }
  @media screen and (max-width: 500px) {
    width: 22px;
    height: 22px;
  }
`;

const LoginIcon = styled(Icon)`
  margin-left: 0;
  margin-top: -3px;
  padding: 0;
  margin-right: 0.5rem;
`;

const SideArea = styled.div`
  display: flex;
  align-items: center;
  order: 3;
  min-width: 240px;
  justify-content: flex-end;
  @media screen and (max-width: 1600px) {
    min-width: 190px;
  }
  @media screen and (max-width: 1400px) {
    min-width: 160px;
  }
  @media screen and (max-width: 500px) {
    min-width: 80px;
  }
`;

const btnStyle = css`
  display: flex;
  align-items: center;
  position: relative;
  text-align: center;
  justify-content: center;
  padding: 11px 12px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  @media screen and (max-width: 1400px) {
    padding: 10px 8px;
    border-radius: 7px;
    font-size: 12px;
  }
`;

const StyleBtnLink = styled(Link)`
  ${btnStyle};
  background: white;
  color: var(--art-dark);
  background-clip: padding-box;
  border: solid 1px transparent;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(to right, var(--art-active), var(--art-rose));
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: white;
    background-color: linear-gradient(to right, var(--art-active), var(--art-rose));
  }
  @media screen and (max-width: 1170px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  ${btnStyle};
  background-color: var(--art-active);
  color: white;
  min-width: 100px;
  white-space: nowrap;
  svg {
    margin-top: 1px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--art-active-light);
    color: white;
  }
  @media screen and (max-width: 500px) {
    padding: 7px 10px;
    border-radius: 7px;
    min-width: auto;
  }
`;

const DropdownButton = styled.button`
  ${btnStyle};
  background-color: var(--art-active);
  border: solid 1px var(--art-active);
  color: white;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  @media screen and (max-width: 1300px) {
    padding: 18px 11px;
    border-radius: 7px;
    font-size: 12px;
  }
  @media screen and (max-width: 1170px) {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: ${({ active }) => (active ? css`var(--art-active)` : 'transparent')};
    border: solid 2px ${({ active }) => (active ? css`var(--art-active)` : css`var(--art-dark)`)};
  }
  @media screen and (max-width: 1070px) {
    padding: 5px;
    width: 35px;
    height: 35px;
  }
  @media screen and (max-width: 911px) {
    padding: 5px;
    width: 30px;
    height: 30px;
    border-width: 2px;
  }
  @media screen and (max-width: 500px) {
    width: 22px;
    height: 22px;
    padding: 0;
  }
`;

const DropArea = styled.div`
  position: relative;
`;

const DropdownUserMenu = styled.ul`
  ${dropMenuStyle};
  transform: translate(10px, 10px);
  @media screen and (max-width: 500px) {
    transform: translate(15px, 10px);
  }
`;

const DropdownActions = styled.ul`
  ${dropMenuStyle};
  min-width: 200px;
  transform: translate(10px, 14px);
  @media screen and (max-width: 1300px) {
    padding: 17px 10px;
    border-radius: 7px;
    font-size: 12px;
  }
  @media screen and (max-width: 1000px) {
    right: auto;
    left: 0;
  }
  @media screen and (max-width: 500px) {
    transform: translate(0,10px);
  }
`;

const Dropdown = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
`;

const SmallPlus = styled.div`
  position: relative;
  width: 11px;
  display: block;
  margin-right: 10px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 11px;
    background-color: var(--art-default-extra);
    height: 1.2px;
  }
  &:before {
    transform: rotate(90deg);
  }
  &:after {
    transform: rotate(180deg);
  }
`;

const Plus = styled.div`
  position: relative;
  width: 15px;
  display: flex;
  margin-right: 10px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 15px;
    background-color: #ffffff;
    height: 2px;
  }
  &:before {
    transform: rotate(90deg);
  }
  &:after {
    transform: rotate(180deg);
  }
  @media screen and (max-width: 1300px) {
    margin-right: 0;
  }
  @media screen and (max-width: 1170px) {
    width: ${({ active }) => (active ? 10 : 15)}px;
    top: ${({ active }) => (active ? 0 : -1)}px;
    &:before,
    &:after {
      width: ${({ active }) => (active ? 10 : 15)}px;
      transition: all 150ms ease-in-out;
      background-color: ${({ active }) => (active ? 'white' : css`var(--art-dark)`)};
    &:before {
      transform: ${({ active }) => (active ? css`rotate(-45deg)` : css`rotate(90deg)`)};
      right: ${({ active }) => (active ? -3 : 0)}px;
    }
    &:after {
      transform: ${({ active }) => (active ? css`rotate(45deg)` : css`rotate(180deg)`)};
      right: ${({ active }) => (active ? 3 : 0)}px;
    }
  }
  @media screen and (max-width: 500px) {
    width: ${({ active }) => (active ? 7 : 10)}px;
    &:before,
    &:after {
      width: ${({ active }) => (active ? 7 : 10)}px;
    }
    &:before {
      right: ${({ active }) => (active ? -2 : 0)}px;
    }
    &:after {
      right: ${({ $active }) => ($active ? 2 : 0)}px;
    }
  }
`;

const AsideInfo = styled.aside`
  background: linear-gradient(270deg, var(--art-active) 6.67%, var(--art-rose) 95.16%);
  position: relative;
  padding: 15px 10px;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    padding: 10px 18px;
    top: 0;
  }
`;

const Content = styled.div`
  max-width: 1440px;
  color: white;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 15px;
  }
  a {
    color: white;
  }
  p {
    margin-bottom: 0;
  }
  @media screen and (max-width: 600px) {
    text-align: left;
  }
`;

export default TopMenu;
