/* eslint-disable max-len */
export default {
  sell_label: 'I want to sell my artwork',
  sell_description: "Place the artwork in the Artocratia Market to get more opportunities for sale. All users of the Artocratia will see your artwork, and the platform's algorithms will promote it.",
  collect_label: 'I want to add a artwork to my collection',
  collect_description: 'If you do not plan to sell the artwork, place it in your personal collection on Artocratia. You can configure visibility and show it only to your friends or to all users of the site. Also, your artwork can be selected by the curator for placement in the Online Museum of Artocratia.',
  next: 'Next',
  moderation: 'Your application is under consideration',
  text_top: 'The status of consideration of your application can be seen in',
  orders: 'Applications and tariffs',
  text_bottom: 'We will also send a notification of approval. If we have not approved your application, try to send it again.',
  close: 'Close',
  back: 'Back',
  by_tariff: 'Place according to the tariff',
  by_tariff_description: 'The tariff is a comprehensive approach to selling your art. You can put several works up for sale and promote your art. You also get access to a closed Telegram channel for communicating with like-minded people and colleagues. ',
  one_time: 'Place one-time for {{ price }} {{ currency }}',
  one_time_description: 'One-time placement makes it possible to upload only one work for sale, publication is carried out through moderation and subsequent payment',
  amateur: 'Users of the "Amateur" role cannot upload works',
  unpublish_label: 'Unpublish',
  unpublish_description: 'Artwork will only be visible to you in your account',
  admin_change_tariff: 'Hello! I have run out of tariff, I want to discuss options for changing my subscription',
  limit_tariff_info: 'If you want to continue uploading works, we can revise the terms of your tariff or change it to a more suitable one. You can also post a one-time job for {{ count }} {{ currency }}',
  change_tariff: 'Change the tariff',
  sell_one: 'Sell one-time for {{ count }} {{ currency }}',
  price_range: 'Price "from" must be less than cost "to"',
  price_digit: 'Only numbers are allowed',
  tariff_limit: 'Your tariff limit has expired',
};
