/* eslint-disable max-len */
export default {
  title: 'Artocratia - Log in',
  name: 'Log in',
  info: 'Fill in the fields to log in',
  username: 'Login or email',
  username_placeholder: 'Enter a login or email',
  password: 'Password',
  password_placeholder: 'Enter a password',
  login_btn: 'Log in',
  forgot_password: 'Forgot your password?',
  registration: 'Create an account',
  error_username: 'Check username or email',
  error_password: 'Check password',
  no_account: "Don't have an account?",
};
