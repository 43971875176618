/* eslint-disable max-len */
export default {
  meta: 'Payment',
  name_artwork: 'Payment for placing the artwork in the market',
  name: 'Job Title',
  artist: 'Artist',
  owner: 'Owner',
  price: 'Price per month',
  pay: 'Pay',
};
