/* eslint-disable max-len */
export default {
  update: 'Update',
  send_request: 'Request more information',
  offered_works: 'You have offered artworks for the exhibition',
  offer_works: 'Offer artworks for the exhibition',
  select_artworks: 'Select the artworks',
  placeholder_message: 'Enter your message to the curator',
  remove: 'Delete your request',
  offered_exhibition: 'You have requested the artwork for the exhibition',
  offer_exhibition: 'Request the artwork for the exhibition',
  select_exhibitions: 'Select an event',
  message_collector: 'A message to at the collector',
  delete: 'The request is successfully deleted',
  delete_one: 'The artwork is successfully deleted from the list',
  confirm_curator: 'The request is confirmed, expect feedback from the collector',
  remove_from: 'Delete from the request',
  remove_info: 'To delete, select (reject all)',
  delete_all: 'Reject all',
  confirm_all: 'Confirm all',
  artworks_info: 'You have confirmed the request, expect feedback from the collector. In case you click delete, the collector will still be able to contact you since they have already been notified. We do not recommend deleting the application before negotiations. It is impossible to recover the offer',
  delete_exhibition: 'Event is successfully removed from the list',
  confirm_collector: 'Application is confirmed, expect feedback from the curator',
};
