/* eslint-disable max-len */
export default {
  name: 'Guides',
  label: 'Journal',
  all_news: 'All the news',
  archive: 'Archive',
  editor_choice: "Editors' choice",
  look_all: 'See all',
  share_label: 'Share the news',
  no_items: 'Nothing is found',
  no_request_result: 'Nothing is found at your request "{{ request }}"',
  search_placeholder: 'Enter a name',
  share_description: 'Article {{ name }} from social network for art lovers - Artocratia',
  interesting: 'Interesting?',
  interesting_text: 'Reading the full article is available for registered Artocratia users. Login to the platform and get unlimited access to interesting materials about art.',
  login_register: 'Login or register',
  journal_placeholder: 'Enter name',
  all: 'Read all articles in the Journal',
  more: 'Read more',
  author: 'Author',
  authors: 'Authors',
  read_theme: 'Read articles on this topic',
  comments: 'Comments',
  back: 'Back to all articles',
  hidden_tariff: 'Explore tariffs',
};
