import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import _ from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';

import { DARK } from '../../helpers/colors';

import Icon from '../Icon';

const ArtAward = ({
  form, name, push, remove,
}) => {
  const normalizeDate = (fieldName, dateFormat) => {
    if (dateFormat) {
      form.setFieldValue(fieldName, dateFormat.format('YYYY'));
    }
  };
  const renderInput = (props, openCalendar, fieldName) => {
    const clear = () => {
      // eslint-disable-next-line react/prop-types
      props.onChange({ target: { value: '' } });
      form.setFieldValue(fieldName, '');
    };
    return (
      <div className="d-flex align-items-center">
        <input {...props} />
        <div className="d-flex align-items-center">
          <ButtonStyle className="close" type="button" onClick={clear}>&times;</ButtonStyle>
          <ButtonStyle type="button" onClick={openCalendar}>
            <Icon
              icon="calendar"
              colorFill={DARK}
              width={20}
              height={20}
              noHover
            />
          </ButtonStyle>
        </div>
      </div>
    );
  };
  return (
    <Awards>
      {_.get(form, `values.${name}`, []).map((item, index) => (
        <ItemArea
          key={`artist${name}-${index + 1}`}
        >
          <Label>
            Наименование
          </Label>
          <Form.Control
            className="w-100 form-control-lg"
            placeholder="Укажите, наименование награды"
            name={`${name}.${index}.name`}
            value={_.get(form, `values.${name}[${index}].name`)}
            onChange={(e) => form.setFieldValue(`${name}.${index}.name`, e.target.value)}
          />

          <LabelNormal>
            Год получения
          </LabelNormal>
          <Datetime
            locale="en"
            closeOnSelect
            timeFormat={false}
            renderInput={(props, openCalendar) => renderInput(props, openCalendar, `${name}.${index}.date`)}
            inputProps={{
              className: 'form-control-lg w-100',
              placeholder: 'Выберите год',
              readOnly: true,
              name: `${name}.${index}.date`,
            }}
            dateFormat="YYYY"
            className="w-50 wm-100"
            id={`${name}.${index}.date`}
            name={`${name}.${index}.date`}
            value={_.get(form, `values.${name}[${index}].date`, '')}
            onChange={(e) => normalizeDate(`${name}.${index}.date`, e)}
          />

          <LabelNormal>
            Описание награды
          </LabelNormal>
          <Form.Control
            placeholder="Введите текст"
            as={TextareaAutosize}
            className="form-control-lg mb-3"
            name={`${name}.${index}.description`}
            value={_.get(form, `values.${name}[${index}].description`)}
            onChange={(e) => form.setFieldValue(`${name}.${index}.description`, e.target.value)}
          />

          <Button
            type="button"
            variant="outline-primary-link"
            onClick={() => remove(index)}
          >
            Удалить элемент
          </Button>
        </ItemArea>
      ))}

      <ButtonControl>
        <Button
          onClick={() => push({ name: '', description: '', date: '' })}
          type="button"
          variant="primary"
        >
          Добавить элемент
        </Button>
      </ButtonControl>

      <Form.Control.Feedback type="invalid">
        {form.errors[name]}
      </Form.Control.Feedback>
    </Awards>
  );
};

const ButtonStyle = styled.button`
padding: 0;
&.close {
    font-size: 30px;
    font-weight: 100;
    margin-right: 7px;
}
`;

const ButtonControl = styled.div`
width: 100%;
@media screen and (max-width: 812px) {
  padding: 0 20px;
}
`;

const Label = styled.div`
font-style: normal;
font-weight: bold;
font-size: 24px;
margin-bottom: 15px;
@media screen and (max-width: 812px) {
  font-size: 16px;
}
`;

const LabelNormal = styled.div`
font-style: normal;
font-weight: bold;
font-size: 24px;
margin-bottom: 15px;
margin-top: 60px;
@media screen and (max-width: 812px) {
  font-size: 16px;
  margin-top: 40px;
}
`;

const Awards = styled.div`
@media screen and (max-width: 812px) {
  .wm-100 {
    width: 100% !important;
  }
  button {
    width: 100%;
  }
}
`;

const ItemArea = styled.div`
background: white;
padding: 50px;
margin-bottom: 30px;
@media screen and (max-width: 812px) {
  padding: 20px;
}
`;

ArtAward.defaultProps = {
  name: '',
};

ArtAward.propTypes = {
  form: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default ArtAward;
