import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';

import { } from '../../locales';

import '../../css/editor.css';

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const Editor = ({
  placeholder,
  field,
  label,
  className,
  onChange,
  form: { errors, setFieldValue },
  toolbar,
}) => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return '';
  }
  const ref = useRef();
  let value = _.get(field, 'value', '');

  const change = (v) => {
    setFieldValue(field.name, v);
    onChange(v);
  };

  const changeSelection = (range) => {
    const quill = _.get(ref, 'current', null);
    if (quill && range) {
      const { editor } = quill;
      if (_.get(range, 'index') === 0) {
        editor.theme.tooltip.show();
        if (!_.get(range, 'ops', null)) {
          editor.theme.tooltip.position(editor.getBounds(range));
        }
      }
    }
  };

  const input = document.querySelector('input[data-link]');
  if (input && !!input.dataset) {
    input.dataset.link = 'https://artocratia.com';
    input.dataset.video = 'Link to video';
    input.placeholder = 'https://artocratia.com';
  }

  let modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'], // toggled buttons
      // [{ indent: '-2' }, { indent: '+2' }],
      // [{ header: '2' }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction
      // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      // [{ header: [3, 4, false] }],
      // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ font: [] }],
      [{ align: [] }],
      ['link', 'video'],
      ['clean'], // remove formatting button
    ],
  };

  if (toolbar) {
    modules = { toolbar };
  }

  if (value) {
    value = value.replace(/(style=)*/g, '');
    // value = value.replace(/(<p><br><\/p>)*/g, '');
    value = value.replace(/(<p>&nbsp;<\/p>)*/g, '');
  }

  return (
    <>
      {label && label}
      <ReactQuill
        ref={ref}
        value={value}
        modules={modules}
        onChange={change}
        onChangeSelection={changeSelection}
        id={`editor-${field.name}`}
        theme="bubble"
        className={className}
        placeholder={placeholder || ''}
      />
      <Form.Control.Feedback type="invalid">
        {errors && errors[field.name]}
      </Form.Control.Feedback>
    </>
  );
};

Editor.defaultProps = {
  label: '',
  placeholder: 'Введите описание',
  className: 'mt-4',
  toolbar: false,
  onChange: () => {},
};

Editor.propTypes = {
  toolbar: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
};

export default Editor;
