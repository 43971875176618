/* eslint-disable max-len */
export default {
  your_project: 'Unique Project',
  help_project: 'The ability to create your own digital project from scratch or present one already on our platform',
  your_room: 'Viewing Rooms',
  help_room: 'Ability to rent ready-made digital space for your project',
  add_project: 'Join project',
  spaces: 'Spaces',
  send_success: 'Your request has been sent successfully!',
  add: 'Create a unique project with Artocratia Metaverse',
  name: 'Your name',
  name_placeholder: 'Enter your name',
  phone: 'Phone',
  phone_placeholder: 'Enter phone number',
  message: 'Tell us about your project',
  message_placeholder: 'Enter text',
  role: 'Your role',
  artist: 'Artist',
  creator: 'Creator',
  collector: 'Collector',
  brand: 'Brand',
  producer: 'Producer',
  designer: 'Designer',
  marketer: 'Marketer',
  developer: 'Developer / IT Specialist',
  thanks: 'Thank you, our manager will contact you soon',
  agreement: 'By clicking submit button, I agree',
  agreement_link: 'to process my personal data',
  info: 'After submitting this order, Marina Alvitr, the founder of Artocratia, will personally contact you',
  thanks_user: 'Thank you! Your order has been sent. Marina Alvitr will contact you shortly at Artocratia to discuss about your project.',
  hello_moderator: "Hello! I'm interested in collaborating/creating a project in the Artocratia metaverse",
};
