/* eslint-disable max-len */
export default {
  to_fix: 'Comment from card',
  category: 'Category',
  article_number: 'Item number',
  article_placeholder: 'Enter an item number',
  condition: 'Condition',
  condition_placeholder: 'For example: craquelure, crazing, excellent condition, etc.',
  circulation: 'Editions',
  address: 'Address',
  fragility: 'Fragility',
  history: 'History of actions',
  activities: 'Exhibitions, publications, auctions',
  weight: 'Weight',
  insurance: 'Insurance',
  insurance_status: 'Status insurance',
  insured: 'Insured',
  insured_not: 'Not insured',
  certificate: 'Certificate of authenticity',
  certificate_yes: 'Yes',
  certificate_no: 'No',
  trans_store: 'Storage and transportation conditions',
  trans_store_placeholder: 'For example: the rabbet of the frame should be padded to prevent abrasion of the painting’s edge',
  digitization: 'Digitization (You have photos of high quality)',
  digitization_yes: 'Yes',
  digitization_no: 'No',
  notes: 'Notes',
  notes_placeholder: 'Enter any additional notes',
  files: 'Files and documentation',
  paperwork: 'Framing',
  paperwork_placeholder: 'For example: passe-partout, glass, no frame, etc.',
  provenance_name: 'Provenance',
  history_name: 'Story',
  documents_name: 'Documents',
  catalog: 'Catalog of art works',
  share_label: 'Share your artwork',
  meta_artist_name: 'the Artist {{ name }}',
  meta_collection_name: 'Owner {{ name }}',
  meta_artwork_name: 'Artwork {{ name }}',
  meta_description: 'Artist {{ name }} - artworks in collections',
  step_1_info: 'Complete this step so the artwork will be saved as a draft and will be only visible to you. To make it visible to everyone, also complete the step 2',
  step_2_info: 'After completing this step you will be able to publish the artwork. It will be visible for everyone including guests',
  step_3_info: "This is private information regarding the artwork: item number, cost, condition, etc. It's only visible to you, completing is in free form and optional",
  artist_step_1_info: 'Complete this step so the artwork will be saved as a draft and will be only visible to you. To make it visible to everyone, also complete the step 2',
  artist_step_2_info: 'After completing this step you will be able to publish the artwork. It will be visible for everyone including guests',
  my_collection: 'My collection',
  my_artwork: 'My artwork',
  my_artworks: 'My artworks',
  photos: 'Photo',
  photos_link: 'link to the photobank',
  publish_name: 'Artwork',
  name: 'Update artwork {{ name }}',
  add: 'Basic information',
  info: 'Extended information',
  no_name: 'Artwork is unnamed',
  field_name: "Artwork's name",
  name_placeholder: 'Enter name',
  link_placeholder: 'Enter an url',
  field_artist: 'Artist',
  artist_placeholder: "Enter an artist's name",
  artist_untitled: 'Artist is unknown',
  field_album: 'Album',
  album_info: 'Select in which one of your albums the exhibit will be stored',
  no_album: 'Without an album',
  info_name: 'Information about the artwork',
  view: 'View',
  edit: 'Edit',
  field_category: 'Category',
  select_category: 'Select a category',
  circulation_placeholder: 'Enter a serial number out of total number of copies if they exist, for example: 2/300',
  material_tech: 'Art media',
  import_tech: 'Import the art media and convert them into a tagged system. You can always delete it in this field if necessary',
  material_tech_placeholder: 'Select art media',
  field_price: 'Cost',
  price_placeholder: 'Enter the cost of the artwork',
  currency: 'Currency',
  field_size: 'Size',
  field_description: 'Description',
  desc_placeholder: 'Enter a description',
  desc_placeholder_en: 'Description in English (not required)',
  field_period: 'Period of creation',
  period_placeholder: 'Year, period or free form',
  additional: 'This is private information regarding the artwork: <b>item number</b>, <b>cost</b>, <b>status</b>, etc. <br/> You can add it but it will be only visible to you',
  additional_label: 'It will be only visible to you',
  additional_name: 'Private information',
  additional_add: 'Add private info',
  back: 'Back to step',
  next: 'Next',
  private_info: 'Private information, only visible to you',
  select_variant: 'Choose for whom you publish artwork',
  cant_published: "You can't publish artwork because there are no photos",
  set_exhibition: 'Select or create an exhibition to which you will attach works',
  attach: 'Added',
  show_attached: 'Show added artworks',
  show_all: 'Show all artworks',
  go_exhibition: 'Select or create an exhibition',
  no_selected: 'No any selected artworks',
  artist: 'Artist artworks',
  market: 'Market',
  find_artist: 'Find yourself or create a new artist card',
  send_artist_order: 'Send a request to manage an artist card',
  artist_order_requested: 'Your request is sent, wait for notification. We will consider your application during the day',
  price: 'Price',
  bye: 'Purchase for',
  price_help: 'The cost is used only for the Market if you sell the work. If you have not specified a price, it will be displayed as "Price on Request"',
  not_necessary: 'optional',
  price_request: 'Price on request',
  conversation: 'Artwork comments',
  show_location: 'Specify location',
  location_select_info: 'You can choose from your locations or create a new one. To create, enter a name and click "Add"',
  type_my_location: 'Select from my',
  short_dimension: 'Unit',
  confirmed_exhibition: 'Artwork confirmed for the event',
  price_name: 'Enter current price',
  price_info: 'It is recommended to specify an exact price or range, this greatly increases the chance of selling the work',
  price_set: 'Set',
  price_one: 'Exact price',
  price_range: 'Range',
  add_price: 'Specify price',
  for_all: 'For everyone including guests',
  for_friends: 'For my friends only',
  for_view: 'Artwork visibility',
  for_view_info: 'This feature is only for Collectible Artworks. If you are going publish to the Market, the work will become public to everyone',
};
