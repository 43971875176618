/* eslint-disable max-len */
export default {
  artist: 'Artist',
  collector: 'Collector',
  art_dealer: 'Art Dealer',
  gallery: 'Gallery',
  month: 'per month',
  year: 'for year',
  month_short: 'Month',
  year_short: 'Year',
  pay_installments: 'Pay in installments',
  success_payed: 'Thank you! Payment successfully accepted, your subscription is active',
  success_payed_education: 'Thank you! Payment accepted. The course is available in your account.',
  pending_payed: 'Your payment is being processed. We will notify you when the money is credited to the account and the functions are active',
  wrong_payed: 'Your payment failed, please try again',
  error_payment: 'An error occurred while paying, if the money was debited please contact the administration',
  tariff: 'Tariffs',
  has_expired: "Attention! Your plan ends in {{ count }} days. If you don't renew, your works will no longer appear in the market. To fix this, renew the selected plan or choose another after ending of the current",
  go_another: 'go to new',
  description: 'Fare payment',
  no_close: "Don't close the page, we're checking the payment",
  your_plan: 'Your {{ type }} plan',
  paid_to: 'Paid to',
  attention: 'Pay attention!',
  extend: 'Extend',
  is_paid: 'You cannot pay for tariff of type "{{ current }}" because you already have a purchased tariff "{{ tariff }}". If you made a mistake in choosing or want to change the placement option, contact the administrator',
  info_btn: 'Individual fare',
  proposal_name: "Couldn't find a suitable rate or have questions?",
  proposal_desc: 'Artocratia is always in touch! We will help you choose an individual tariff for your needs and answer all your questions. To contact the manager, click on the button below and we will write to you.',
  dialog_btn: 'Choose Tariff',
  admin_message: 'Hello! I want to clarify some information about tariff "{{ name }}"',
  promotion_time: 'Promotion time from {{ from }} to {{ to }}',
  payment_month: 'Renew your plan for a month for {{ price }} {{ currency }}',
  payment_year: 'Renew your plan for a year for {{ price }} {{ currency }} per month. The total cost is {{ price_year }} {{ currency }}',
  payment_month_discount: 'Promotion! Extend your plan for a month with {{ discount }}% for {{ price }} {{ currency }} Offer valid from {{ from }} to {{ to }}',
  payment_year_discount: 'Promotion! Extend your plan for a year at {{ discount }}% for {{ price_year }} {{ currency }} with a monthly payment of {{ price }} {{ currency }} Offer valid from {{ from } } to {{ to }}',
  monthly: 'monthly',
  yearly: 'annual',
  name: 'Tariff',
  name_for: 'For',
  period: 'Period',
  price: 'Cost',
  price_discount: 'Price with discount',
  know_rules: 'By making a purchase you agree to',
  payment_rules: 'payment rules',
  and_rules: 'familiar with',
  offer_rules: 'offer',
  and_more: 'and',
  policy_rules: 'privacy policy',
  payment: 'Order payment',
  pay: 'Pay',
  go_art: 'Go to Art Creation',
  go_education: 'Go to study',
  promo_code: 'Cost including promo code',
  change_role: 'Change account type',
  learn: 'Study tariffs',
  bye: 'Buy',
  know_more: 'Learn more',
  select_tariff: 'Select the appropriate tariff',
  register: 'Register',
  auth_google: 'Log in with Google',
  free: 'Free',
  ask_manager: 'Ask a question to the manager',
  learn_price: 'Find out the price of the tariff {{ name }}',
  pay_tariff: 'Choose the tariff {{ name }}',
  select_payment: 'Select payment option',
  total_amount: 'Tariff - {{ name }}. Total amount for {{ for }} is',
  back: 'Back to order',
  current_tariff: 'Your current tariff',
  no_params: 'No required parameters',
  installment_time: 'Select installment period',
  select_method: 'Select how to pay',
  select_mode: 'Select payment mode',
};
