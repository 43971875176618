import _ from 'lodash';

import config from '../config';
import action from './en/action';
import album from './en/album';
import albums from './en/albums';
import alerts from './en/alerts';
import artist from './en/artist';
import artists from './en/artists';
import artwork from './en/artwork';
import artworks from './en/artworks';
import chat from './en/chat';
import collection from './en/collection';
import collections from './en/collections';
import comments from './en/comments';
import compilation from './en/compilation';
import create from './en/create';
import declination from './en/declination';
import dimension from './en/dimension';
import dropFiles from './en/dropFiles';
import education from './en/education';
import educations from './en/educations';
import emoji from './en/emoji';
import exhibition from './en/exhibition';
import exhibitions from './en/exhibitions';
import favorites from './en/favorites';
import filter from './en/filter';
import form from './en/form';
import friendship from './en/friendship';
import info from './en/info';
import institution from './en/institution';
import institutions from './en/institutions';
import link from './en/link';
import location from './en/location';
import login from './en/login';
import main from './en/main';
import market from './en/market';
import metaspace from './en/metaspace';
import news from './en/news';
import notification from './en/notification';
import notification_modal from './en/notification_modal';
import notifications from './en/notifications';
import offer from './en/offer';
import order from './en/order';
import payment from './en/payment';
import posts from './en/posts';
import promo from './en/promo';
import reset from './en/reset';
import search from './en/search';
import secret from './en/secret';
import settings from './en/settings';
import signup from './en/signup';
import status from './en/status';
import upload from './en/upload';
import user from './en/user';
import users from './en/users';
import validate from './en/validate';
import viewingRoom from './en/viewingRoom';

export const LANG_EN = 'en';

export const EN_HOST = _.get(config, 'hostname');

export const EN_DOMAIN = `${_.get(config, 'schema')}://${EN_HOST}`;

export const localeRu = {
  action,
  album,
  albums,
  alerts,
  artist,
  artists,
  artwork,
  artworks,
  chat,
  collection,
  collections,
  comments,
  compilation,
  create,
  declination,
  dimension,
  dropFiles,
  education,
  educations,
  emoji,
  exhibition,
  exhibitions,
  favorites,
  filter,
  form,
  friendship,
  info,
  institution,
  institutions,
  link,
  location,
  login,
  main,
  market,
  metaspace,
  news,
  notification,
  notification_modal,
  notifications,
  offer,
  order,
  payment,
  posts,
  promo,
  reset,
  search,
  secret,
  settings,
  signup,
  status,
  upload,
  user,
  users,
  validate,
  viewingRoom,
};
