/* eslint-disable max-len */
export default {
  no_artist: 'Nothing is found',
  no_artworks: 'No artworks',
  no_artists_by_letter: 'No artist names starting with "{{ letter }}" found',
  no_artists_by_letter_and_request: 'No artists found by "{{ request }}" and by "{{ letter }}"',
  new_popular_name: 'New names at Artocratia',
  new_selling_name: 'Works can be purchased',
  one_artist: 'Of an artist',
  many_artists: 'Artists',
  my: 'My artists',
  title: 'CABINET - MY ARTISTS',
  suggest_edit: 'Suggest edits',
  suggest_success: 'Thank you! We have received your message',
  suggest_artist: 'Suggest adjustments for the artist',
  suggest_placeholder: 'Tell us what you would like to add, correct, or change',
};
