/* eslint-disable max-len */
export default {
  name: 'Action',
  edit: 'Edit',
  delete: 'Delete',
  send: 'Send',
  save: 'Save',
  save_draft: 'Save the draft',
  await: 'Please, wait...',
  reset: 'Cancel',
  send_moderation: 'Submit for moderation',
  remove_favorites: 'Delete from favourites',
  save_changes: 'Save the changes',
  change: 'Change',
  hide: 'Hide',
  remove_email: 'Remove email',
  publish: 'Publish',
  liked: 'Liked',
  reply: 'Reply',
  publish_variant: 'Publish variants',
  unpublish: 'Unpublish',
  unpublished: 'Not published',
  push_unpublish: 'Remove from publication',
  moderation: 'Moderation',
  moderation_info: 'Editing and unpublishing will be available after moderation',
  push_publish: 'Publish',
  cant_publish: "You can't publish the material because {{ why }}",
  share: 'Share',
  add: 'Add',
  select: 'Select',
  set: 'Set',
  clear: 'Clean',
  clear_form: 'Clear form',
  no_variants: 'There are no options',
  alert_action: 'Material {{ action }}',
  alert_removed: 'Removed from publication',
  alert_sent: 'Currently being moderated, please, wait for approval',
  published: 'Published',
  error_published: 'Error with publishing, please refresh the page',
  sure: 'Are you sure you want to {{ action }} {{ type }} "{{ name }}"',
  sure_no_name: 'Are you sure you want to {{ action }} {{ type }}',
  view: 'View',
  published_on: 'Published',
  publish_all: 'For everyone',
  publish_friends: 'For friends',
  publish_market: 'In the market',
  publish_collection: 'In the museum',
  select_artworks: 'Add artworks',
  publish_link: 'Access by link',
  access_publish_link: 'provide access via the link for',
  info_publish_link: 'Access will be provided by the link, but when you change the publication option, the link will be deleted',
  published_by_link: 'available by link',
  choice: 'Choose what to do with "{{ name }}"',
  for_choice: 'For whom do you want to publish the work "{{ name }}"?',
  where_choice: 'Where do you want to publish the work "{{ name }}"?',
  pay_publish: 'Publish to market one artwork',
  pay_update: 'Extend Payout',
  left: 'Ending {{ left }}',
  one_pay: 'You are going to publish one artwork "{{ name }}" at the market',
  one_pay_desk: 'The cost of publication for one month is {{ count }} {{ currency }}',
  one_pay_info: 'By clicking "publish" button, you agree to',
  one_pay_offer: 'terms of the offer',
  next_pay: 'You are going to extend the placement of artwork "{{ name }}" in the market',
  next_pay_desc: 'The cost of an extension for one month is {{ count }} {{ currency }}',
  next_publish: 'Extend',
  success_payed: 'Payment was successful!',
  error_payment: 'Error when forming payment, if this happens again, contact the administration',
  one_publish_market: 'In the market for {{ count }} {{ currency }}',
  need_to_pay: 'Payment required',
  contact_moderator: 'Contact Manager',
  wait_pay: 'Waiting for payment in {{ count }} {{ currency }}',
};
