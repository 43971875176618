/* eslint-disable max-len */
export default {
  offers: 'Applications',
  notes_input: 'Create notes',
  notes_label: 'Make notes for "{{ name }}"',
  notes_placeholder: 'Enter notes',
  notes_exists: 'Has notes',
  status: 'Status',
  edit_info: 'The private information regarding the artwork: item number, cost, status, etc. It will be only visible to you',
  additional: 'Private info',
  additional_sm: 'Additional',
  publish_name: 'artwork',
  no_name: 'No name',
  no_artist: 'Artist is unknown',
  no_items: 'Nothing is found',
  no_request_result: 'Nothing is found at your request "{{ request }}"',
  no_filter_result: "Sorry, we couldn't find any matches for that",
  no_request_filter_result: "Sorry, we couldn't find any matches for \"{{ request }}\"",
  remove_alert: "Artwork can't be restored",
  no_item: "You haven't created a collection yet",
  create: 'Would you like to create?',
  add: 'Add an artwork',
  title_collection: 'CABINET - MY COLLECTION',
  my_collection: 'My collection',
  works: 'Artworks',
  description: 'Description',
  import: 'Import of artworks',
  title_artist_collection: 'CABINET - MY WORKS',
  my_artworks: 'My artworks',
  description_artist: 'About artist',
  order_info: 'To publish artworks made by you, you need to',
  order_request: 'send an application',
  order_and: 'and',
  order_tariff: 'choose a subscription plan',
  order: 'Submit an application',
  no_artist_item: "You don't have any jobs",
  attach: 'Added',
  select_exhibitions: 'Select exhibition',
  go_exhibition: 'Select an exhibition',
  no_selected: 'No works selected',
  set_exhibition: 'Set an exhibition',
  show_all: 'Show all',
  show_attached: 'Show selected',
  market: 'Artworks on the market',
  main_market: 'Market',
  market_artists: 'Artists',
  market_dealers: 'Art Dealers',
  market_collectors: 'Collectors',
  market_galleries: 'Gallery Representatives',
  market_top: 'Top artworks',
  market_new: 'New artworks',
  market_available: 'Available artworks',
};
