/* eslint-disable max-len */
export default {
  about: 'About the album',
  about_author: 'About the owner of the album',
  collections: 'Collections',
  name: 'Album "{{ name }}"',
  title: '{{ name }} - Album from the private collection of {{ owner }}',
  description: 'Album "{{ name }}" from the private collection of art works {{ owner }} at the social network for art lovers - Artocratia',
  write_message: 'Send a message',
  works_in_album: '({{ total }}) artworks in the album in total',
  placeholder: 'Enter a name or an artist',
  share_label: 'Share the album',
  share_description: 'Take a look at the collection of {{ name }}',
  access_message: 'Access to the album is provided by the link',
  saved: "You've successfully saved the album",
  my: 'My album',
  all: 'My albums',
  publish_name: 'album',
  update: 'Update album by "{{ name }}"',
  add: 'Add an album',
  update_artworks: 'Add artworks to the album "{{ name }}"',
  add_artworks: 'Add artworks to album',
  you_can: 'You can create an album and add artworks',
  attached: 'You added to album {{ count }} {{ name }}',
  field_name: 'Album name',
  field_name_placeholder: 'Enter name',
  field_about: 'About album',
  field_about_placeholder: 'Enter a description',
  field_image: 'Cover photo',
  image_info: 'Photo must be of good quality, at least 512x512 px and no more than 2 MB. If the photo is not uploaded, the cover is formed from the photos of last 10 added artworks',
  upload: 'Upload image',
  upload_info: 'button {:} or drag a file to this area',
  selected: 'Selected {{ count }}',
  to_select: 'Add artworks',
  close: 'Close',
  created: 'Album is created',
  show_all: 'Show all',
  show_selected: 'Show selected',
  albums: 'Albums',
};
