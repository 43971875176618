/* eslint-disable max-len */
export default {
  activities_type: 'Type',
  activities_type_placeholder: 'Select type',
  activities_activity: 'Name',
  activities_activity_placeholder: 'Enter name',
  activities_period: 'date',
  period_placeholder: 'Year, period 2000-2002 or free form',
  activities_link: 'Link',
  activities_link_placeholder: 'enter url',
  activities_description: 'Description',
  activities_description_placeholder: 'Type',
  activities_delete: 'Delete event',
  activities_add: 'Add event',
  history_type: 'Type',
  history_type_placeholder: 'Select type',
  history_date: 'Date',
  history_price: 'Estimated value',
  history_price_placeholder: 'Enter numbers',
  history_location: 'Location',
  history_location_placeholder: 'Type an address or place name',
  history_description: 'Description',
  history_description_placeholder: 'Type',
  history_currency: 'Currency',
  history_delete: 'Delete action',
  history_add: 'Add action',
  provenance_date: 'Date',
  provenance_comment: 'Comment',
  provenance_comment_placeholder: 'Describe the event',
  provenance_delete: 'Delete provenance',
  provenance_add: 'Add provenance',
};
