/* eslint-disable max-len */
export default {
  name: 'Notifications',
  email: 'Email for notifications',
  no_email: "You don't have an email",
  select_all: 'Select all',
  on_email: 'By email',
  check_enter: 'New login to Artocratia',
  check_offer: 'New exhibition invitations',
  check_comments: 'New comments',
  check_messages: 'New messages',
  check_posts: "New friends' posts",
  check_friendships: 'New friends requests',
  check_news: 'News by Artocratia',
};
