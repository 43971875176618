/* eslint-disable max-len */
export default {
  message: 'Message',
  subscribe_reply: 'Follow back',
  add_to_friends: 'Send a friend request',
  unsubscribe: 'Unfollow',
  block: 'Block',
  unblock: 'Unblock',
  not_familiar: "You don't know each other",
  subscribed_to_you: 'Follows you',
  your_friend: 'Your friend',
  you_track: 'You follow',
  you_block: 'You blocked',
};
