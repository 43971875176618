/* eslint-disable max-len */
export default {
  title: 'Artocratia - create an account',
  name: 'Create an account',
  info: 'Fill in the information and you will receive an email with a activation link',
  email: 'Email',
  email_placeholder: 'Enter your email',
  password: 'Password',
  password_placeholder: 'Enter your password',
  recommendation: 'Security recommendations',
  strong: 'Strong',
  length: 'Minimum length is 8 characters',
  lowercase_characters: 'Lowercase characters',
  uppercase_characters: 'Uppercase characters',
  numbers_special_characters: 'Digits and special characters',
  confirm_password: 'Confirm your password',
  confirm_password_placeholder: 'Repeat password',
  passwords_are_match: 'Passwords match',
  passwords_are_not_match: "Passwords don't match",
  services: 'Log in using services',
  have_account: 'Already have an account?',
  authorization: 'Log in',
  phone: 'Phone',
  phone_placeholder: 'Place your phone',
  not_correct_phone: 'Invalid phone number',
  registration: 'Register',
  complete_title: 'Artocratia - Complete profile creation',
  complete_name: 'The final step of creating your profile',
  complete_info: 'Please, introduce yourself and select your role to complete the registration process',
  complete_user: 'First name and last name',
  complete_role: 'Choose your role in the Artocratia. The more specific you are about your role, the more enjoyable your experience on the platform will be and the easier it will be for you to build relationships with other users. The role can be changed at any time.',
  complete_username_placeholder: 'Enter your name',
  complete_label_amateur: 'Art lover',
  complete_label_dealer: 'Art Dealer',
  complete_label_gallery: 'Gallery',
  complete_label_curator: 'Curator',
  complete_label_collector: 'Collector',
  complete_btn: 'Complete profile creation',
  hello_name: 'Welcome to Artocratia, {{ name }}!',
  hello_text: 'Buy and sell art, find new people to join your project team, meet creative industry professionals, participate in digital exhibitions, learn art world news and gain new experience and knowledge.',
  hello_go: 'Start',
};
