/* eslint-disable max-len */
export default {
  no_items: 'Nothing is found',
  placeholder_search: 'Search among {{ total }} artworks',
  placeholder_search_view: 'Enter title or artist',
  no_request_result: 'Nothing is found at your request "{{ request }}"',
  no_filter_result: "Sorry, we couldn't find any matches for that",
  name: 'Selections of artworks',
  editor_select: "Editors' choice",
};
