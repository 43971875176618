import { gql } from '@apollo/client';

const fragmentTariff = gql`
  fragment TariffFragment on Tariff {
    _id
    name
    name_for
    price_month
    price_year
    installments
    text
    order
    popular
    status
    discount
    discount_from
    discount_to
    discount_month
    discount_month_from
    discount_month_to
    about_small
    about_big
    close_text
    priority
    blocks {
      name
      color
      horizontal
      description
      section
      button
      button_url
      order
      image {
        key
      }
      cover {
        key
      }
    }
  }
`;

export const GQL_GET_TARIFFS = gql`
  query GetTariffs {
    tariffs {
      ...TariffFragment
    }
  }
  ${fragmentTariff}
`;

export const GQL_GET_TARIFF = gql`
  query GetTariff($_id: String!) {
    tariff(_id: $_id) {
      ...TariffFragment
    }
  }
  ${fragmentTariff}
`;

export const GQL_PROMO_TARIFF = gql`
  mutation SendPromoTariff($promo: String!, $tariff_id: String!) {
    promoTariff(promo: $promo, tariff_id: $tariff_id) {
      price_month
      price_year
    }
  }
`;

export const GQL_PAY_PROGRESS = gql`
  mutation PayTariff($data: PayTariff) {
    payTariff(data: $data)
  }
`;

export const GQL_NEXT_PAY_PROGRESS = gql`
  mutation NextPayTariff {
    nextPayTariff {
      token
      system
    }
  }
`;
