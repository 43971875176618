/* eslint-disable max-len */
export default {
  connect: 'Join project',
  contact: 'Contact manager',
  hello_moderator: 'Hello! I want to know more about the Viewing Rooms project. Rent price {{ rent_price }} {{ currency }}',
  hello_moderator_name: 'Hello! I want to know more about the Viewing Rooms project - "{{ name }}". Rent price {{ rent_price }} {{ currency }}',
  send_success: 'Your request has been sent successfully!',
  add: 'Rent Ready Space Viewing room "{{ name }}"',
  name: 'Your name',
  name_placeholder: 'Enter a name',
  phone: 'Phone',
  phone_placeholder: 'Enter phone number',
  message: 'Tell us about your project',
  message_placeholder: 'Enter text',
  booking: 'Rent space',
  agreement: 'By clicking the submit button, I agree',
  agreement_link: 'to process my personal data',
  thanks_user: "Thank you, {{ name }}! We've received all the information we need. Our manager will contact you today.",
  thanks: 'Thank you! We received all the necessary information. Today our manager will contact you at the specified phone number',
};
