import baseConfig from './config.base';

const config = {
  ...baseConfig,
  environment: 'production',
  schema: 'https',
  hostname: 'artocratia.com',
  api: 'https://api.artocratia.com/',
  RAZZLE_API_GRAPHQL_PATH: 'https://api.artocratia.com/graphql',
  RAZZLE_API_GRAPHQL_SSR_PATH: 'http://127.0.0.1:3001/graphql',
  GOOGLE_MAP_API_URL: 'https://maps.googleapis.com/maps/api/js?v=weekly',
  GOOGLE_MAP_API_KEY: 'AIzaSyBxPXFRq5GWVQmkfQlvfDF6vrQvyTGICXg',
  METRIC_GOOGLE: 'G-10G3TB8PSF',
  STRIPE_PUB_KEY: 'pk_live_51MhXStJaB3GRpNiAayzN0HuMyehz7V0L2XlV8UCRJuWpu4brkrq7wpMwHVDE4toBwNxCizWhTKvbyzwTNVT6bEJ900AS38f6Nx',
};

export default config;
