/* eslint-disable max-len */
export default {
  title: 'Artocratia - Password reset',
  name: 'Reset password',
  info: 'To reset your password, please, enter your email',
  email: 'Email',
  email_placeholder: 'Enter your email',
  btn: 'Reset',
  send_btn: 'Sending the link...',
  complete_title: "Artocratia - Change the password'",
  complete_name: 'Completing password reset',
  complete_password: 'Password',
  complete_password_placeholder: 'Enter new password',
  complete_repeat_password: 'Repeat password',
  complete_repeat_password_placeholder: 'Repeat password',
  complete_btn: 'Complete password reset',
};
