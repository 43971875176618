/* eslint-disable max-len */
export default {
  accessible_art: 'Affordable art',
  accessible_art_info: '* Everything up to 50,000₽',
  top_artists_art: 'Top',
  new_art: 'Novelties of the month',
  top_artists: 'Artists',
  top_artists_info: '* Our everything',
  top_institution: 'Institutions',
  top_institutions_info: '* Here the artworks presented by galleries',
  how_connect: 'How to get to our Market?',
  need_user: 'You just need to click on the button and choose a tariff',
  need_guest: 'You just need to click on the button, register and choose a tariff',
  publish_art: 'Publish your artworks',
  top_collectors: 'Collectors',
  top_collectors_info: '* Those who really appreciate',
  top_dealers: 'Dealers',
  top_dealers_info: '* Those who sell what you have created',
  watch_all: 'View all artworks for sale',
};
