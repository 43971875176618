/* eslint-disable max-len */
export default {
  name: 'Exhibitions',
  my_name: 'My exhibitions',
  no_items: 'Nothing is found',
  placeholder_search: 'Search among {{ total }}',
  no_request_result: 'Nothing is found at your request "{{ request }}"',
  no_filter_result: "Sorry, we couldn't find any matches for that",
  no_request_filter_result: "Sorry, we couldn't find any matches for \"{{ request }}\"",
  count_artworks: 'Artworks on exhibition',
  until: 'Until',
  main_name: 'Exhibition events',
  time: 'Today {{ date }}',
  last: 'Last call',
  editor: "Editor's choice",
  current: 'New offline',
  online: 'New online',
  view_all: 'View all',
  weekend: 'This weekend',
};
