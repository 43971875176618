/* eslint-disable max-len */
export default {
  work: 'artwork',
  work_1: 'artwork',
  work_2: 'artworks',
  works: 'artworks',
  year: 'year',
  years_2: 'year',
  years: 'years',
  user: 'user',
  users: 'users',
  album: 'album',
  albums_2: 'albums',
  albums: 'albums',
  collection: 'collection',
  collections: 'collections',
  compilation: 'selections',
  compilations: 'selections',
  exhibition: 'exhibitions',
  exhibitions: 'exhibitions',
  institution: 'museum',
  institutions: 'museums',
  day: 'day',
  days: 'days',
  comment: 'comment',
  of_comment: 'of a comment',
  comments: 'comments',
  month: 'month',
  month_2: 'months',
  months: 'months',
  lecture: 'lecture',
  lecture_2: 'lectures',
  lectures: 'lectures',
};
