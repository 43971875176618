/* eslint-disable react/no-array-index-key */
import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
} from 'react-bootstrap';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import _ from 'lodash';
import { useTranslation } from '../hooks/useTranslation.jsx';

import ArtField from './ArtField';
import {
  ButtonControl,
  DefaultLabel,
  H3,
} from '../../helpers/accountStyles';

const ArtProvenance = ({
  form,
  form: { setFieldValue },
  name,
  push,
  remove,
  label,
  className,
  labelOutside,
}) => {
  const { t } = useTranslation();
  // This is fast input text when many fields
  const [values, setValues] = useState([]);

  const handleChangeFor = (propertyName, value, isNumber = false) => {
    if (isNumber) {
      if (value) {
        const newValue = value.trim().replace(',', '.');
        const is = /^[\d]{0,10}?[.]?([\d]{0,2})$/.test(newValue);
        if (is) {
          setValues({ ...values, [propertyName]: newValue });
          setFieldValue(propertyName, newValue);
          return true;
        }
        return false;
      }
      setValues({ ...values, [propertyName]: '' });
      setFieldValue(propertyName, '');
      return true;
    }
    setValues({ ...values, [propertyName]: value });
    setFieldValue(propertyName, value);
    return true;
  };

  useEffect(() => {
    setValues(_.get(form, 'values'));
  }, [_.get(form, 'values')]);

  return (
    <div id="provenance">
      {labelOutside && label}
      <Form.Group controlId={name}>
        {!labelOutside && label}
        {_.get(values, `${name}`, []).map((item, index) => {
          let errors = null;
          if (!!form.errors
            && !!form.errors[name]
            && !!form.errors[name][index]) {
            errors = form.errors[name][index];
          }
          return (
            <div
              key={`artwork${name}-${index}`}
              className={className}
            >
              {_.get(errors, 'main') && (
                <div className="position-absolute mt-n4 badge badge-pill badge-danger">
                  {errors.main}
                </div>
              )}

              <H3 start={1}>
                {t('provenance_date', { ns: 'secret' })}
                <DefaultLabel>
                  {t('not_necessary', { ns: 'main' })}
                </DefaultLabel>
              </H3>
              <Field
                name={`${name}[${index}].dates`}
                placeholder={t('period_placeholder', { ns: 'secret' })}
                className="form-control-lg"
                component={ArtField}
              />

              <H3>{t('provenance_comment', { ns: 'secret' })}</H3>
              <Form.Control
                as={TextareaAutosize}
                placeholder={t('provenance_comment_placeholder', { ns: 'secret' })}
                name={`${name}[${index}].description`}
                value={_.get(values, `${name}[${index}].description`)}
                onChange={(e) => handleChangeFor(`${name}[${index}].description`, _.get(e, 'target.value', ''))}
                className={`d-flex flex-2 mt-3 mb-3 form-control form-control-lg  ${_.get(errors, 'description') ? 'is-invalid' : ''}`}
              />

              {_.get(errors, 'description') && (
                <div className="position-absolute mt-n4 badge badge-pill badge-danger">
                  {_.get(errors, 'description')}
                </div>
              )}

              <Button
                type="button"
                variant="outline-black"
                onClick={() => remove(index)}
                className="mt-2 rounded-0 px-5 col-md-auto"
              >
                {t('provenance_delete', { ns: 'secret' })}
              </Button>
            </div>
          );
        })}
      </Form.Group>

      <ButtonControl>
        <Button
          type="button"
          onClick={() => push({ dates: '', description: '' })}
          size="xl"
          className="px-5 my-3 my-md-0"
          variant="outline-primary"
        >
          {t('provenance_add', { ns: 'secret' })}
        </Button>
      </ButtonControl>
    </div>
  );
};

ArtProvenance.defaultProps = {
  label: '',
  className: '',
  labelOutside: true,
};

ArtProvenance.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelOutside: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default ArtProvenance;
