/* eslint-disable max-len */
export default {
  conversation: 'Discussion',
  push_reply: 'Replied to your comment',
  push_wright: 'Left a new comment',
  remove: 'Are you sure you want to delete this comment?',
  recovery_impossible: 'Recovering will not be possible',
  send_abuse: 'Are you sure you want to report this message?',
  recive_abuse: 'The message is reported!',
  updated: 'Your comment is updated',
  published: 'Your comment is published',
  empty: 'No comments here yet',
  let_comment: 'Comment on the post',
  info_alert: 'To leave a comment, please, log in or sign in',
  text_required: '{{ name }}, for leaving the comment, please, fill in the text',
  user: 'User',
  placeholder: '{{ user }}, leave a comment',
  abuse: 'Report',
  you_reply: 'You are replying to {{ who }}',
  me: 'yourself',
  to_user: 'the user {{ name }}',
  answer: 'answers user {{name }}',
  answer_self: 'answers himself',
};
