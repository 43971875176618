/* eslint-disable max-len */
export default {
  title: 'My account - favourites',
  albums: 'Albums',
  artists: 'Artists',
  works: 'Artworks',
  collections: 'Collections',
  compilations: 'Compilations',
  institutions: 'Institutions',
  exhibitions: 'Exhibitions',
  journal: 'Journal',
  friends: 'Friends',
  my_connections: 'My connections',
  album_placeholder: 'Enter an album name',
  artists_placeholder: 'Enter an artist name',
  artworks_placeholder: 'Enter a name of an artwork or an artist',
  collections_placeholder: 'Enter a name of a collector',
  compilations_placeholder: 'Enter a name of a compilation',
  exhibitions_placeholder: 'Enter a name of an exhibition',
  friends_placeholder: 'Enter a name of a friend',
  institutions_placeholder: 'Enter a name of an institution',
  journal_placeholder: 'Enter a name of an article',
  my: 'My favorites',
};
