import { gql } from '@apollo/client';

const fragment = gql`
  fragment CategoryFragment on Category {
      _id
      user_id
      status
      name
      description
  }
`;

export const GQL_GET_CATEGORIES_ALL = gql`
  query GetCategoriesAll {
    categoriesAll {
      _id
      user_id
      status
      name
      description
    }
  }
`;

export const GQL_UPDATE_CATEGORY = gql`
  mutation UpdateCategory($data: CategoryInput) {
    updateCategory(data: $data) {
      ...CategoryFragment
    }
  }
  ${fragment}
`;

export const GQL_GET_CATEGORY = gql`
   query GetCategory($_id: ID) {
    category(_id: $_id) {
      ...CategoryFragment
    }
  }
  ${fragment}
`;
