import _ from 'lodash';
import React, { useEffect } from 'react';

import { localeRu } from '../../locales';
import useAsyncData from './useAsyncData';
/**
 * Fetch data from JSON file
 * @returns {Promise<unknown>}
 * @private
 */
async function _fetchData() {
  return localeRu;
}
/**
 * Check if NS in both params, key and options
 * @param resultKey - key from params
 * @param options - options
 * @returns {boolean} - is NS in both params, key and options
 * @private
 */
function _doubleNs(resultKey, options) {
  if (resultKey.length > 1 && _.has(options, 'ns')) {
    return resultKey[0] === options.ns;
  }
  return true;
}

/**
 * Check if NS in params
 * @param resultKey - key from params
 * @param options - options
 * @returns {boolean} - is NS in params
 * @private
 */
function _hasNs(resultKey, options) {
  if (resultKey.length > 1) {
    return true;
  }
  return _.has(options, 'ns');
}

const _replaceParams = (k, value, res) => {
  const reg = new RegExp(`({{*.?${k}*.?}})`, 'g');
  return res.replace(reg, value || '');
};

const _isValuesStringOrInt = (options) => {
  let result = true;
  _.keys(options).forEach((k) => {
    if (k !== 'ns' && !_.isString(options[k]) && !_.isInteger(options[k])) {
      result = false;
    }
  });
  return result;
};

/**
 * Translate function
 * @returns {{ t: function()|*}}
 */
export const useTranslation = () => {
  const {
    data, loading, error, run,
  } = useAsyncData(null);

  useEffect(async () => {
    await run(_fetchData, { cacheKey: 'translation' });
  }, []);

  const t = (key, options) => {
    const resultKey = key.split('.');
    if (!_doubleNs(resultKey, options)) {
      return `The key "${key}" does not match NS.`;
    }
    if (!_hasNs(resultKey, options)) {
      return `The key "${key}" does not have an NS specified.`;
    }
    if (!_isValuesStringOrInt(options)) {
      return 'No string or integer values in options.';
    }
    // Get NS from a key with point or from options
    const ns = resultKey.length > 1 ? resultKey[0] : _.get(options, 'ns', false);
    const keyName = resultKey.length > 1 ? resultKey[1] : key;

    if (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return '';
    }

    if (loading || !data) {
      return '';
    }

    const current = _.get(data, ns, {});

    let result = _.get(current, keyName, '');

    if (result) {
      const clearOptions = options;

      if (_.has(options, 'ns')) {
        delete clearOptions.ns;
      }

      if (!_.isEmpty(clearOptions)) {
        _.keys(clearOptions).forEach((k) => {
          result = _replaceParams(k, clearOptions[k], result);
        });
      }

      return result;
    }
    return '';
  };

  return { t };
};

export function withTranslation(Component) {
  return function WrappedComponent(props) {
    const { t } = useTranslation();
    return <Component {...props} t={t} />;
  };
}
