/* eslint-disable max-len */
export default {
  present: 'New product presentation',
  phone: 'Phone number',
  placeholder: 'Your phone number',
  name: 'Full name',
  placeholder_name: 'Your full name',
  email: 'E-mail',
  placeholder_email: 'Your e-mail',
  send: 'Buy webinar recording',
  no: 'No, thank you',
  success: 'Thank you! You have successfully registered!',
  thanks: 'Thank you! Your application is submitted.',
  redirect: 'Now you will be redirected to the telegram group. A link to the event will be sent to the group.',
  go: 'Go to Telegram',
  phone_required: 'Phone number is required',
  email_required: 'Email is required',
  name_required: 'Full name is required',
  was_sent: 'Thank you! You have successfully registered, if you have not done yet, then follow the link and connect telegram channel',
  fill_form: 'Fill out the form, join the meeting and get new opportunities on the Artocratia platform very first.',
  required_phone: 'Phone is a required field',
  discount: 'Registered users get a discount of {{ discount }} %',
  success_payed: 'Successful payment!',
  error: 'Error! Payment is failed',
  no_close: "Don't close the page!",
  send_price: 'Buy the webinar for {{ price }} rub',
};
