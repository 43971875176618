/* eslint-disable import/no-extraneous-dependencies */
import {
  ApolloClient, HttpLink, InMemoryCache, split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import _ from 'lodash';
import fetch from 'node-fetch';

import config from '../config';

const isProduction = process.env.NODE_ENV === 'production';

const ssrMode = typeof window === 'undefined';

let apolloClient;
// eslint-disable-next-line no-underscore-dangle
const state = !ssrMode ? window.__APOLLO_STATE__ : '';

const razzlePath = config.RAZZLE_API_GRAPHQL_PATH;

const graphQLUrl = !ssrMode
  ? razzlePath
  : config.RAZZLE_API_GRAPHQL_SSR_PATH;

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpLink = new HttpLink({
  fetch,
  uri: graphQLUrl,
  fetchOptions: {
    credentials: 'include',
    mode: 'cors',
  },
});

const wsLink = !ssrMode ? new GraphQLWsLink(createClient({
  url: graphQLUrl
    .replace('http://', 'ws://')
    .replace('https://', 'wss://')
    .replace('graphql', 'subscriptions'),
})) : null;

function createInstance(req = null, res = null) {
  let cache = new InMemoryCache();
  cache = cache.restore(state);

  let link = httpLink;

  let authLink;

  if (_.get(req, 'cookies.token')) {
    const { token = null } = req.cookies;
    authLink = setContext((param, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));
  }

  if (res !== null) {
    const errors = onError(({
      graphQLErrors,
      networkError,
      operation,
      forward,
    }) => {
      if (graphQLErrors) {
        // eslint-disable-next-line consistent-return
        graphQLErrors.forEach((err) => {
          const oldHeaders = operation.getContext().headers;
          let code = _.get(err, 'extensions.code', 500);
          code = +code;
          const redirect = _.get(err, 'extensions.redirect', null);
          switch (code) {
            case 301:
            case 302:
              setContext({
                headers: {
                  ...oldHeaders,
                  status: code,
                },
              });
              if (res && redirect) {
                res.redirect(code, redirect);
              }
              return forward(operation);
            case 400:
            case 401:
            case 402:
            case 403:
            case 404:
              setContext({
                headers: {
                  ...oldHeaders,
                  status: code,
                },
              });
              if (res) {
                // eslint-disable-next-line no-console
                console.log(err);
                res.status(code);
              }
              return forward(operation);
            default:
              // eslint-disable-next-line no-console
              console.log(err);
              break;
          }
        });
      }
      if (networkError) {
        // eslint-disable-next-line no-console
        console.log(networkError);
      }
    });

    link = errors.concat(link);
  }

  if (authLink) {
    link = authLink.concat(link);
  }

  link = !ssrMode
    ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (definition.kind === 'OperationDefinition' && definition.operation === 'subscription');
      },
      wsLink,
      httpLink,
    ) : link;

  return new ApolloClient({
    link,
    cache,
    ssrMode,
    connectToDevTools: !ssrMode && !isProduction,
    // ssrForceFetchDelay: 100,
    defaultOptions,
  });
}

export function createApolloClient(req = null, res = null) {
  if (ssrMode) {
    // Make sure to create a new client for every server-side request so that data
    // isn't shared between connections (which would be bad)
    return createInstance(req, res);
  }
  if (!apolloClient) {
    apolloClient = createInstance(req, res);
  }
  return apolloClient;
}
