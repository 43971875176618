/* eslint-disable max-len */
export default {
  label: 'Collection',
  name: 'Collection {{ name }}',
  about: 'About the collection',
  start: 'The start of collection',
  about_owner: 'About the owner',
  send_message: 'Send a message',
  title: '{{ name }} - Private collection',
  description: 'The owner of the private collection - {{ name }}',
  title_ac: 'Artist {{ name }}',
  description_ac: '{{ name }} - information, artworks and collection of the artist',
  in_collection: 'Artworks in collection ({{ total }})',
  in_sell: 'Artworks for sale ({{ total }})',
  in_all: 'All artworks ({{ total }})',
  search_placeholder: 'Enter a name or an artist',
  share_label: 'Share the collection',
  share_description: 'Take a look at the collection of {{ name }}',
};
