/* eslint-disable max-len */
export default {
  no_dialogs: "You don't have any dialogs yet",
  not_delete: 'This action will not delete the dialogue completely',
  info_delete: "The dialogue will be deleted completely only in case all the participants do this. To recover it, it's enough for you or your interlocutor to start the conversation again",
  no_messages: 'No messages here yet',
  sure_delete: 'Are you sure you want to delete this message?',
  enter_message: 'Enter your message',
  error: 'Sorry, an error has occurred. Please, try again later',
  loading: 'Loading the chat...',
  my: 'My account - my messages',
  name: 'Chats',
  find_friends: 'Find friends',
  find_chats: 'Search by the chats',
  not_found: 'Nothing is found',
  administrator: 'Contact the administration',
  hello: 'Hello',
  select_dialog: 'Select a dialogue on the left to start a conversation',
  start_dialog: 'Start a dialogue with {{ name }}',
  files: 'Files',
  hello_to_bye: 'Hello, {{ name }}! I’m interested in your artwork {{ link }}',
  was: 'Last seen',
  was_read: 'Read',
  not_read: 'Delivered',
  guest: 'Guest',
  need_text: 'You need to enter text',
  need_reload: 'Reload the page',
  can_help: "If you have any questions, please chat with us. We'll try to help.",
  can_messenger: 'You can also use our messenger chats',
  chat_name: 'Chat support',
  you_right: 'You are writing',
  support_right: 'Support',
  send: 'Send',
  input_message: 'Enter messages',
};
