/* eslint-disable max-len */
export default {
  login: 'Log in',
  logout: 'Log out',
  main: 'Main page',
  yes: 'Yes',
  no: 'No',
  from: 'From',
  to: 'To',
  currency: 'Currency',
  account: 'Account',
  your_account: 'Your current account',
  loading: 'Loading...',
  await_check: 'Please, wait',
  my_collection: 'My collection',
  registration: 'Create an account',
  alert_information: 'Information',
  alert_warning: 'Warning',
  alert_error: 'Attention',
  alert_success: 'Success!',
  go_to_page: 'Go to page',
  go_to_home: 'Homepage',
  no_page: 'Page not found or not created yet',
  error: 'Error',
  error_access: 'Access denied',
  login_or_register: 'Log in or create an account',
  button_add_artwork: 'Add an artwork',
  button_add_collection: 'Add an collection',
  button_add_exhibition: 'Add an exhibition',
  menu_news: 'News',
  menu_profile: 'My profile',
  menu_favourites: 'Favourites',
  menu_collection: 'Collection',
  menu_collections: 'Collections',
  menu_my_artworks: 'My artworks',
  menu_educations: 'My courses',
  menu_artworks: 'Artworks',
  menu_albums: 'Albums',
  menu_artists: 'Artists',
  menu_tariffs: 'Tariffs',
  menu_exhibitions: 'Exhibitions',
  menu_my_exhibitions: 'My exhibitions',
  menu_museums: 'My museums',
  menu_contacts: 'Contacts',
  menu_locations: 'Locations',
  menu_notifications: 'Notifications',
  menu_messages: 'Messages',
  menu_settings: 'Settings',
  menu_users: 'Users',
  menu_friends: 'Friends',
  apply: 'Apply',
  reset: 'Cancel',
  draft: 'Only for me',
  draft_name: 'Draft',
  input_text: 'Enter text',
  collector: 'Collector',
  collectors: 'Collectors',
  artist_role: 'Artist',
  curator: 'Curator',
  curators: 'Curators',
  dealer: 'Art Dealer',
  dealers: 'Art Dealers',
  gallery: 'Gallery',
  galleries: 'Galleries',
  specialist: 'Professional',
  specialists_all: 'Professionals',
  amateur: 'Art lover',
  friends: 'Friends',
  follow_me: 'Followers',
  i_follow: 'I follow',
  he_follow: 'Follows',
  blocked: 'Blocked',
  send: 'Send',
  qr_code: 'QR Code of the page',
  qr_question: 'How to scan a QR Code',
  close: 'Close',
  contact: 'Contact us',
  all_rights: 'All rights reserved',
  show: 'Show',
  show_more: 'Show more',
  hide: 'Hide',
  no_name: 'Untitled',
  no_indicate: 'Not indicated',
  no_artist: 'Artist is unknown',
  no_params: 'No required parameters!',
  no_owner: 'Owner is unknown',
  no_image: 'No image',
  show_map: 'Show the map',
  hide_map: 'Hide the map',
  more: 'More',
  image: 'Image',
  access_all: 'Everyone including guests',
  status_all: 'All',
  status_published: 'Published',
  status_edit: 'Editing',
  status_moderation: 'Moderation',
  not_necessary: 'optional',
  not_found: 'Page not found or not created yet',
  error_chunk: 'This application is updated, please refresh the page',
  error_all: 'An error has occurred, please refresh the page and try again',
  file_to_big: 'File {{ name }} - {{ size }} MB is too large, the allowed size is {{ maxSize }}',
  file_type: 'Uploaded file extension {{ name }} is not valid!',
  file_exist: 'The file {{ name }} exist',
  age_label: 'Content 18+',
  age_warning: 'Content has age restrictions',
  age_button: 'I’m over 18',
  photo: 'Photo {{ num }}',
  preview: 'Preview {{ num }}',
  calendar: 'Calendar',
  hide_warning: 'Viewing for registered users only',
  interesting: 'Interesting?',
  interesting_text: 'Viewing content is available only for registered users Artocratia. Login to the platform and get unlimited access to interesting materials about art.',
  abuse: 'Complaint on "{{ name }}"',
  abuse_placeholder: 'Describe the complaint',
  share: 'Share posts "{{ name }}"',
  'share placeholder': 'Enter message text',
  share_another: 'Share on other social networks',
  editor_info: 'Information about editor and supported upload file formats',
  publish: 'Publish',
  menu_subscribe: 'Expand tariff',
  button_add_post: 'Write a post',
  button_add_gallery: 'Add gallery',
  search: 'Search',
  authorisation: 'Log in to access content',
  auth_error: 'Email busy',
  auth_error_message: 'A user with this email already exists. Please log in or recover your password',
  google_auth: 'Sign in or sign up with a Google account',
};
