/* eslint-disable max-len */
export default {
  notifications: 'Notifications',
  cabinet_title: 'My account - Notifications',
  all: 'All notifications',
  participation_requests: 'Participation requests',
  friend_requests: 'Friend requests',
  comments: 'Comments',
  likes: 'Likes',
  about_likes: 'about likes',
  about_comments: 'about comments',
  about_friendships: 'about friend requests',
  about_offers: 'about offers',
  no_items: 'You have no notifications {{ text }}',
  no_more_items: 'No more notifications',
  mark_all_read: 'Mark all as read',
  mark_read: 'Mark as read',
  mark_unread: 'Mark as unread',
  remove_marked: 'Delete everything that marked',
  remove_all: 'Remove all',
  remove_all_name: 'all notifications',
  remove_alert: 'Recovery is not possible',
  offered_friendship: 'offered friendship',
  answer_friendship: 'responded to your offer',
  send_comment: 'left a new comment',
  send_post: 'published a new post',
  marked_artwork: 'highlighted artwork',
  marked_institution: 'said institution',
  marked_album: 'noted the album',
  marked_exhibition: 'noted event',
  marked_you: 'noted you page',
  text: 'Text',
  is_read: 'Read',
  is_new: 'New',
  confirm_artworks: 'confirmed the application for participation in the event',
  request_artworks: 'requesting participation of artworks in the event',
  confirm_exhibitions: 'confirmed the application for artwork participation in the event',
  request_exhibitions: 'asks for the product to the event',
  request_info: 'To view the artworks, confirm or reject, go to the event',
  confirm_info: 'To view confirmed artworks, go to the event',
  message_reply_offer_works: 'You have been removed related application, but you can still contact the curator',
  message_reply_offer_exhibitions: 'You have been removed related application, but it is still possible to contact the collector',
  message_offer_exhibitions: 'They removed the operation request for the exhibition, but you can still reach them',
  message_offer_works: 'They deleted the offer of artworks for the exhibition, but you can still contact them',
  remove_somebody: 'The offer was deleted by someone',
  post: 'to your post',
};
