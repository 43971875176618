/* eslint-disable max-len */
export default {
  in_your_collection: '{{ count }} {{ declination }} of this artist in your collection',
  birth_date: 'Date of birth',
  birth_death: 'Date of death',
  place_residence: 'Place of residence',
  education: 'Education',
  award: 'Awards',
  award_date: 'Date',
  award_description: 'Description',
  no_artworks: 'Sorry, this artist has no publicly available artworks yet',
  no_request_result: 'Nothing is found at your request "{{ request }}" ',
  no_filter_result: "Sorry, we couldn't find any matches for that",
  no_request_filter_result: "Sorry, we couldn't find any matches for \"{{ request }}\"",
  works: '({{ total }}) artworks of the artist in total',
  placeholder_search: 'Enter a name of an artwork or an artist',
  share_label: 'Share',
  share_description: 'Artist {{ name }} and the artworks in collections',
  meta_title: 'Artist {{ name }} | Artocratia',
  meta_description: 'Artist {{ name }} and his art works in collections and for sale',
  info: "Information about the artist can be changed by proposing changes in the author's card in the section \"Artists\" in your account",
  alert_success: 'Thank you! The artist card will be available after moderation',
  add: 'Add a new artist',
  label_name: 'Name of the artist',
  help_name: 'Please, write the name in the following order: first name, last name, patronymic',
  placeholder_name: 'Name of the artist',
  label_live: "The artist's life",
  check_death: 'Set the date of death',
  label_birth: 'Date of birth',
  placeholder_birth: 'Enter a date',
  label_death: 'Date of death',
  placeholder_death: 'Enter a date',
  label_birth_in: 'Born',
  placeholder_birth_in: 'Specify where the artist was born',
  label_live_in: 'Lived/lives in',
  placeholder_live_in: 'Specify the place of residence of the artist',
  label_death_in: 'Died',
  placeholder_death_in: 'Specify the place of death of the artist',
  label_about: 'Comments',
  placeholder_about: 'A brief biography',
  label_photo: 'Photo of the artist',
  help_photo: 'The photo must be at least 512x512 px',
  upload: 'Upload an image',
  upload_info: 'Use the {:} button or drag the file to this area',
  info_edit: 'Here you can create or update information about yourself as an artist, your artworks will be attached to this card',
  save_success: 'Successfully updated',
  need_select: 'Need to select artist',
  send_connect: 'Management request is successfully submitted',
  info_new: "First you need to create or connect an artist whose card will present your artworks. If you're already presented on Artocratia as an artist, select it from the list below and send us a request, or create a new card. Your profile may differ from the artist's card",
  as_user: 'This artist is presented by',
  can_bye: 'Artworks can be purchased',
  in_collections: 'Works in collections',
  all: 'All artworks',
  all_artist: 'All artists',
};
