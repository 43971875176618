/* eslint-disable import/no-unresolved, max-len */
import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import _ from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { createContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import NewComments from './components/account/comments/newComments';
import ChatWindow from './components/account/messages/chatWindow';
import NewMessages from './components/account/messages/newMessages';
import Auth from './components/layouts/auth';
import Master from './components/layouts/master';
import { GQL_GET_ME } from './graphql/queries';
import { scrollElementId, scrollIntoView } from './helpers/main';
import updateStatus from './status';

const fallback = () => (<div className="loader"><div className="bar" /></div>);

const Home = loadable(() => import('screens/Home'), { ssr: true, fallback: fallback() });
const Error = loadable(() => import('screens/Error'), { ssr: true, fallback: fallback() });
const Page = loadable(() => import('screens/Page'), { ssr: true, fallback: fallback() });

const Login = loadable(() => import('screens/Login'), { ssr: true, fallback: fallback() });
const Signup = loadable(() => import('screens/Signup'), { ssr: false, fallback: fallback() });
const SignupComplete = loadable(() => import('screens/SignupComplete'), { ssr: false, fallback: fallback() });
const Reset = loadable(() => import('screens/Reset'), { ssr: false, fallback: fallback() });
const ResetComplete = loadable(() => import('screens/ResetComplete'), { ssr: false, fallback: fallback() });
const Search = loadable(() => import('screens/Search'), { ssr: true, fallback: fallback() });
const ChangeEmail = loadable(() => import('screens/ChangeEmail'), { ssr: false, fallback: fallback() });
const Tariffs = loadable(() => import('screens/Tariffs'), { ssr: true, fallback: fallback() });

// Checkout
const Checkout = loadable(() => import('screens/payments/checkout'), { ssr: false, fallback: fallback() });
const CheckoutEducation = loadable(() => import('screens/payments/checkout-education'), { ssr: false, fallback: fallback() });

// Artworks
const Artworks = loadable(() => import('screens/artworks'), { ssr: true, fallback: fallback() });
const ArtworkView = loadable(() => import('screens/artworks/view'), { ssr: true, fallback: fallback() });

const Market = loadable(() => import('screens/market'), { ssr: true, fallback: fallback() });
const MarketUsers = loadable(() => import('screens/market/users'), { ssr: true, fallback: fallback() });
const MainMarket = loadable(() => import('screens/market/main'), { ssr: true, fallback: fallback() });

// Collections
const Collections = loadable(() => import('screens/collections'), { ssr: true, fallback: fallback() });

const Compilations = loadable(() => import('screens/compilations'), { ssr: true, fallback: fallback() });
const CompilationView = loadable(() => import('screens/compilations/view'), { ssr: true, fallback: fallback() });

const Albums = loadable(() => import('screens/users/album'), { ssr: true, fallback: fallback() });

// Institutions
const Institutions = loadable(() => import('screens/institutions'), { ssr: true, fallback: fallback() });
const InstitutionsView = loadable(() => import('screens/institutions/view'), { ssr: true, fallback: fallback() });

// News
const News = loadable(() => import('screens/news'), { ssr: true, fallback: fallback() });
const NewsMain = loadable(() => import('screens/news/main'), { ssr: true, fallback: fallback() });
const NewsView = loadable(() => import('screens/news/view'), { ssr: true, fallback: fallback() });

// Users
const UserView = loadable(() => import('screens/users/view'), { ssr: true, fallback: fallback() });

// Artists
const Artists = loadable(() => import('screens/artists'), { ssr: true, fallback: fallback() });
const ForArtists = loadable(() => import('screens/ForArtists'), { ssr: true, fallback: fallback() });
const ArtistView = loadable(() => import('screens/artists/view'), { ssr: true, fallback: fallback() });

// Exhibitions
const ExhibitionsAll = loadable(() => import('screens/exhibitions'), { ssr: true, fallback: fallback() });
const ExhibitionView = loadable(() => import('screens/exhibitions/view'), { ssr: true, fallback: fallback() });

// Educations
const Educations = loadable(() => import('screens/educations'), { ssr: true, fallback: fallback() });
const EducationsView = loadable(() => import('screens/educations/view'), { ssr: true, fallback: fallback() });

const PaymentPage = loadable(() => import('screens/Payment'), { ssr: false, fallback: fallback() });

// Account
const Notification = loadable(() => import('screens/account/notification'), { ssr: false, fallback: fallback() });

const AccountUsers = loadable(() => import('screens/account/users'), { ssr: false, fallback: fallback() });

const AccountIndex = loadable(() => import('screens/account'), { ssr: false, fallback: fallback() });

const AccountMessages = loadable(() => import('screens/account/chat'), { ssr: false, fallback: fallback() });

const AccountArtworks = loadable(() => import('screens/account/artworks'), { ssr: false, fallback: fallback() });
const UpdateAccountArtworks = loadable(() => import('screens/account/artworks/update'), { ssr: false, fallback: fallback() });

const AccountEducations = loadable(() => import('screens/account/educations'), { ssr: false, fallback: fallback() });
const AccountEducationsView = loadable(() => import('screens/account/educations/view'), { ssr: false, fallback: fallback() });
const AccountLectureView = loadable(() => import('screens/account/educations/lecture'), { ssr: false, fallback: fallback() });

const AccountArtists = loadable(() => import('screens/account/artists'), { ssr: false, fallback: fallback() });
const UpdateAccountArtists = loadable(() => import('screens/account/artists/update'), { ssr: false, fallback: fallback() });

const AccountExhibitions = loadable(() => import('screens/account/exhibitions'), { ssr: false, fallback: fallback() });
const UpdateAccountExhibitions = loadable(() => import('screens/account/exhibitions/update'), { ssr: false, fallback: fallback() });

const AccountInstitutions = loadable(() => import('screens/account/institutions'), { ssr: false, fallback: fallback() });
const UpdateAccountInstitutions = loadable(() => import('screens/account/institutions/update'), { ssr: false, fallback: fallback() });

const AccountAlbums = loadable(() => import('screens/account/albums'), { ssr: false, fallback: fallback() });
const UpdateAccountAlbums = loadable(() => import('screens/account/albums/update'), { ssr: false, fallback: fallback() });

const AccountFavorites = loadable(() => import('screens/account/favorites'), { ssr: false, fallback: fallback() });

const AccountLocations = loadable(() => import('screens/account/locations'), { ssr: false, fallback: fallback() });

const AccountPosts = loadable(() => import('screens/account/posts'), { ssr: false, fallback: fallback() });
const AccountPostView = loadable(() => import('screens/account/posts/view'), { ssr: false, fallback: fallback() });

const Presentation = loadable(() => import('screens/Presentation'), { ssr: true, fallback: fallback() });

const Metaverse = loadable(() => import('screens/metaverse'), { ssr: true, fallback: fallback() });
const MetaverseView = loadable(() => import('screens/metaverse/view'), { ssr: true, fallback: fallback() });

const ViewingRoom = loadable(() => import('screens/viewing-room'), { ssr: true, fallback: fallback() });
const ViewingRoomView = loadable(() => import('screens/viewing-room/view'), { ssr: true, fallback: fallback() });

const Navigator = ({ client }) => {
  const ChatContext = createContext();
  const history = useHistory();

  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(null);

  const { data } = useQuery(GQL_GET_ME);

  const me = _.get(data, 'me');

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (!_.get(history, 'location.pathname').includes('/signup/')
        && !_.get(history, 'location.pathname').includes('/reset/')
        && !_.get(history, 'location.pathname').includes('/change-email/')
        && !_.get(history, 'location.pathname').includes('/fast-payment/')
      ) {
        const search = queryString.parse(_.get(history, 'location.search', {}));
        const goto = localStorage.getItem('goto');
        if (goto && !_.get(search, 'repass', false)) {
          history.push(goto);
          localStorage.removeItem('goto');
        }
        if (_.get(search, 'repass', false)) {
          const { repass } = search;
          history.push(repass);
          localStorage.removeItem('goto');
        }
      }
    }
  }, []);

  useEffect(() => {
    const unListen = history.listen((location, action) => {
      updateStatus(client, data);
      // Hide or show the message btn if in messages or not
      setShow(!_.get(location, 'pathname', '').includes('/account/messages'));

      if (_.get(location, 'state.openChat')) {
        // Open chat from any were
        // Use history.push({ pathname: '/self-page', state: { openChat: true, message: 'some message or null' } });
        setText(_.get(location, 'state.message', null));
        setShow(true);
        setOpen(true);
      }

      if (_.get(location, 'state.scrollToNow')) {
        // If you need now to scroll on click
        scrollElementId(location.state.scrollToNow);
      }

      if (_.get(location, 'state.scrollTo')) {
        // This need it if user clicks on card in the list and then comeback to it
        localStorage.setItem('scrollTo', location.state.scrollTo);
      }

      const id = localStorage.getItem('scrollTo');
      // if you go back, look to scroll id element in history state
      if ((action === 'REPLACE' || action === 'POP') && id) {
        scrollElementId(id);
        localStorage.removeItem('scrollTo');
      }
      if (action === 'PUSH') {
        scrollIntoView('#app');
      }
    });
    return () => {
      unListen();
    };
  });

  const master = (page, props = null) => Master(page, props);
  const userMaster = (page, props = null) => Auth(Master(page, props), true, me);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextProcess = () => {
    setOpen(true);
  };

  return (
    <ChatContext.Provider value={contextProcess}>
      {_.get(me, '_id', null) && [
        <NewMessages key={1} me={me} />,
        <NewComments key={2} me={me} />,
      ]}
      {show && (
        <ChatWindow me={me} open={open} setOpen={setOpen} initText={text} />
      )}
      <Switch>
        <Route
          path="/"
          component={_.get(me, '_id', null)
            ? userMaster(AccountPosts, { footer: 'false' })
            : master(Home, { container: 'false' })}
          exact
        />
        <Route path="/tariffs" component={master(Tariffs, { container: 'false' })} exact />
        <Route path="/for-artists" component={master(ForArtists, { container: 'false' })} exact />
        {/* News */}
        <Route path="/journal" component={master(NewsMain)} exact />
        <Route path="/tag-:tag" component={master(News)} exact />
        <Route path="/guidelines" component={master(News)} exact />
        <Route path="/journal/archive" component={master(News)} exact />
        <Route path="/journal/:url" component={master(NewsView)} exact />
        <Route path="/guidelines/:url" component={master(NewsView)} exact />
        {/* Artworks */}
        <Route path="/artworks" component={master(Artworks)} exact />
        <Route path="/art-market" component={master(MainMarket, { container: 'false' })} exact />
        <Route path="/market-place" component={master(Market)} exact />
        <Route path="/market-place/artworks-:section" component={master(Market)} exact />
        <Route path="/market-place/:section" component={master(MarketUsers)} exact />
        <Route path="/market-place/:section/artworks" component={master(Market)} exact />
        <Route path="/artworks/:id([0-9a-fA-F]{24})" component={master(ArtworkView)} exact />
        <Route path="/artworks/:url" component={master(ArtworkView)} exact />
        {/* Courses */}
        <Route path="/educations" component={master(Educations, { container: 'false' })} exact />
        <Route path="/educations/:id([0-9a-fA-F]{24})" component={master(EducationsView, { ChatContext, container: 'false' })} exact />
        <Route path="/educations/:url" component={master(EducationsView, { ChatContext, container: 'false' })} exact />
        {/* Collections */}
        <Route path="/collections" component={master(Collections)} exact />
        {/* Compilations */}
        <Route path="/compilations" component={master(Compilations)} exact />
        <Route path="/compilations/:id([0-9a-fA-F]{24})" component={master(CompilationView)} exact />
        <Route path="/compilations/:url" component={master(CompilationView)} exact />
        {/* Users */}
        <Route path="/@:id([0-9a-fA-F]{24})" component={master(UserView)} exact />
        <Route path="/@:username" component={master(UserView)} exact />
        {/* From user page selection */}
        <Route path="/@:id([0-9a-fA-F]{24})/:section(market|collection|events|about|albums)" component={master(UserView)} exact />
        <Route path="/@:username/:section(market|collection|events|about|albums)" component={master(UserView)} exact />
        {/* Album page */}
        <Route path="/@:user_id([0-9a-fA-F]{24})/albums/:id([0-9a-fA-F]{24})" component={master(Albums)} exact />
        <Route path="/@:username/albums/:id([0-9a-fA-F]{24})" component={master(Albums)} exact />
        <Route path="/@:user_id([0-9a-fA-F]{24})/albums/:url" component={master(Albums)} exact />
        <Route path="/@:username/albums/:url" component={master(Albums)} exact />
        {/* Artists */}
        <Route path="/artists" component={master(Artists)} exact />
        <Route path="/artists/:id([0-9a-fA-F]{24})" component={master(ArtistView)} exact />
        <Route path="/artists/:url" component={master(ArtistView)} exact />
        {/* Exhibitions */}
        <Route path="/exhibitions" component={master(ExhibitionsAll, { all: false })} exact />
        <Route path="/exhibitions-all" component={master(ExhibitionsAll, { all: true })} exact />
        <Route path="/exhibitions/:id([0-9a-fA-F]{24})" component={master(ExhibitionView, { container: 'false' })} exact />
        <Route path="/exhibitions/:url" component={master(ExhibitionView, { container: 'false' })} exact />
        {/* Institutions */}
        <Route path="/institutions" component={master(Institutions)} exact />
        <Route path="/institutions/:id([0-9a-fA-F]{24})" component={master(InstitutionsView, { container: 'false' })} exact />
        <Route path="/institutions/:url" component={master(InstitutionsView, { container: 'false' })} exact />
        {/* Auth */}
        <Route path="/login" component={master(Login)} exact />
        <Route path="/signup" component={master(Signup)} exact />
        <Route path="/signup/:token" component={master(SignupComplete)} exact />
        <Route path="/reset" component={master(Reset)} exact />
        <Route path="/reset/:token" component={master(ResetComplete)} exact />
        <Route path="/change-email/:token" component={master(ChangeEmail)} exact />
        {/* Spec pages */}
        <Route path="/fast-payment/:model/:token" component={master(PaymentPage)} />
        <Route path="/invitation" component={master(Presentation, { container: 'false' })} exact />
        {/* Metaverse */}
        <Route path="/metaverse" component={master(Metaverse, { container: 'false' })} exact />
        <Route path="/metaverse/:id([0-9a-fA-F]{24})" component={master(MetaverseView, { container: 'false' })} exact />
        <Route path="/metaverse/:url" component={master(MetaverseView, { container: 'false' })} exact />
        {/* Viewing Rooms */}
        <Route path="/viewing-rooms" component={master(ViewingRoom, { container: 'false' })} exact />
        <Route path="/viewing-rooms/:id([0-9a-fA-F]{24})" component={master(ViewingRoomView, { container: 'false' })} exact />
        <Route path="/viewing-rooms/:url" component={master(ViewingRoomView, { container: 'false' })} exact />
        {/* Account */}
        <Route path="/account" component={userMaster(AccountIndex)} exact />
        <Route path="/account/my-:path" component={userMaster(AccountIndex)} exact />
        {/* Account checkout education */}
        <Route path="/account/checkout-education" component={userMaster(CheckoutEducation)} exact />
        <Route path="/account/checkout-education/status" component={userMaster(CheckoutEducation)} exact />
        {/* Account checkout tariff */}
        <Route path="/account/checkout:next?" component={userMaster(Checkout)} exact />
        <Route path="/account/checkout:next?/status" component={userMaster(Checkout)} exact />
        {/* Account notifications */}
        <Route path="/account/notifications" component={userMaster(Notification, { footer: 'false' })} exact />
        <Route path="/account/notifications-:path" component={userMaster(Notification, { footer: 'false' })} exact />
        {/* Account users */}
        <Route path="/account/users/:id?" component={userMaster(AccountUsers)} exact />
        {/* Account posts TODO: remove account/posts and change posts/:id to post-:id */}
        <Route path="/account/posts" component={userMaster(AccountPosts, { footer: 'false' })} exact />
        <Route path="/account/posts/:id" component={userMaster(AccountPostView)} exact />
        {/* Account messages */}
        <Route path="/account/messages/:id?" component={userMaster(AccountMessages, { footer: 'false' })} exact />
        {/* Account artworks */}
        <Route path="/account/artworks" component={userMaster(AccountArtworks)} exact />
        <Route path="/account/artworks/:id" component={userMaster(UpdateAccountArtworks, { container: 'false' })} exact />
        <Route path="/account/artworks/:id/step-:number([1,2,3])" component={userMaster(UpdateAccountArtworks, { container: 'false' })} exact />
        {/* Account educations */}
        <Route path="/account/educations" component={userMaster(AccountEducations)} exact />
        <Route path="/account/educations/:id([0-9a-fA-F]{24})" component={userMaster(AccountEducationsView)} exact />
        <Route path="/account/educations/:url" component={userMaster(AccountEducationsView)} exact />
        <Route path="/account/educations/:id([0-9a-fA-F]{24})/:lectureId([0-9a-fA-F]{24})" component={userMaster(AccountLectureView)} exact />
        <Route path="/account/educations/:id([0-9a-fA-F]{24})/:lectureUrl" component={userMaster(AccountLectureView)} exact />
        <Route path="/account/educations/:url/:lectureId([0-9a-fA-F]{24})" component={userMaster(AccountLectureView)} exact />
        <Route path="/account/educations/:url/:lectureUrl" component={userMaster(AccountLectureView)} exact />
        {/* Account artists */}
        <Route path="/account/artists" component={userMaster(AccountArtists)} exact />
        <Route path="/account/artists/:id" component={userMaster(UpdateAccountArtists, { container: 'false' })} exact />
        {/* Account exhibitions */}
        <Route path="/account/exhibitions" component={userMaster(AccountExhibitions)} exact />
        <Route path="/account/exhibitions/:id" component={userMaster(UpdateAccountExhibitions, { container: 'false' })} exact />
        {/* Account institutions */}
        <Route path="/account/institutions" component={userMaster(AccountInstitutions)} exact />
        <Route path="/account/institutions/:id" component={userMaster(UpdateAccountInstitutions, { container: 'false' })} exact />
        {/* Account albums */}
        <Route path="/account/albums" component={userMaster(AccountAlbums)} exact />
        <Route path="/account/albums/:id" component={userMaster(UpdateAccountAlbums, { container: 'false' })} exact />
        {/* Account favorites */}
        <Route path="/account/favorites-:section" component={userMaster(AccountFavorites)} exact />
        {/* Account locations */}
        <Route path="/account/locations" component={userMaster(AccountLocations)} exact />
        {/* Search */}
        <Route path="/search/:search?" component={master(Search)} exact />
        {/* IF user exists by social register */}
        <Route path="/error-auth" component={master(Error, { auth: true })} exact />
        {/* Any page */}
        <Route path="/:url" component={master(Page)} exact />
        {/* Error page */}
        <Route path="/*" component={master(Error)} exact />
      </Switch>
    </ChatContext.Provider>
  );
};

Navigator.propTypes = {
  client: PropTypes.object.isRequired,
};

export default Navigator;
