import { gql } from '@apollo/client';

const artworks = gql`
  fragment ArtworkFavouriteFragment on Artwork {
    _id
    name
    url
    ex_name
    ex_artist
    artist {
      _id
      url
      name
      status
    }
    subcategories {
      _id
      name
    }
    weight {
      value
      dimension
    }
    owner {
      _id
      name
      username
      avatar {
        _id
        key
        fileName
        format
      }
    }
    images {
      _id
      key
      fileName
      format
      order
      width
      height
    }
  }
`;

const news = gql`
  fragment NewsFavouriteFragment on News {
    _id
    url
    name
    created_at
    type
    image {
      _id
      type
      ownerId
      rank
      description
      name
    }
  }
`;

const artists = gql`
  fragment ArtistFavouriteFragment on Artist{
    _id
    name
    url
    avatar {
      _id
      key
      fileName
    }
    dates {
      variant
      kind
      started_at
      ended_at
      location_birth
      location_live
      location_death
    }
  }
`;

const albums = gql`
  fragment AlbumFavouriteFragment on Album {
    _id
    url
    name
    likes
    artworksCount
    avatar {
      _id
      key
      fileName
    }
    artworks {
      _id
      url
      name
      images {
        _id
        key
        fileName
      }
    }
  }
`;

const collections = gql`
  fragment CollectionFavouriteFragment on User {
    _id
    name
    username
    artworks {
      _id
      images {
        _id
        key
        fileName
      }
    }
    avatar {
      _id
      key
      fileName
    }
  }
`;

const exhibitions = gql`
  fragment ExhibitionFavouriteFragment on Exhibition {
    _id
    name
    url
    dates
    days
    worktime
    images {
      _id
      key
      fileName
    }
  }
`;

const institutions = gql`
  fragment InstitutionFavouriteFragment on Institution {
    _id
    name
    url
    location {
      _id
      name
      address
      coordinates
    }
    avatar {
      _id
      key
      fileName
    }
    images {
      _id
      key
      fileName
      format
    }
  }
`;

const compilations = gql`
  fragment CompilationFavouriteFragment on Compilation {
     _id
     name
     status
     section
     description
     image
     favorite {
       _id
     }
     likes
     myLike
     url
  }
`;

export const GQL_GET_FAVORITES_ALL = gql`
  query GetFavoritesAll($modelName: String!, $query: QueryItems) {
    favoritesAll(modelName: $modelName, query: $query) {
      total
      items {
        _id
        user_id
        artworks { ...ArtworkFavouriteFragment }
        news { ...NewsFavouriteFragment }
        artists { ...ArtistFavouriteFragment }
        albums { ...AlbumFavouriteFragment }
        collections { ...CollectionFavouriteFragment }
        compilations { ...CompilationFavouriteFragment }
        exhibitions  { ...ExhibitionFavouriteFragment }
        institutions { ...InstitutionFavouriteFragment }
      }
    }
  }
  ${albums}
  ${artists}
  ${artworks}
  ${collections}
  ${compilations}
  ${exhibitions}
  ${institutions}
  ${news}
`;

export const GQL_FAVORITE_PROCESS = gql`
  mutation setProcessFavorite($model_id: ID!, $modelName: String!) {
    processFavorite(model_id: $model_id, modelName: $modelName)
  }
`;
