/* eslint-disable max-len */
export default {
  title_search: 'Search for "{{ search }}" | Artocratia',
  title: 'Search | Artocratia',
  description: 'Search results page. Social network for art lovers - Artocratia',
  description_search: 'Search results for "{{ search }}". Social network for art lovers - Artocratia',
  by_request_empty: 'No results for the request "{{ search }}"',
  by_request: 'Search results for - "{{ search }}"',
  alert: 'You need to create a request',
  placeholder: 'Enter your request',
  btn: 'Search for',
  collections: 'Collections',
  all_collections: 'All the collections by your request',
  artworks: 'Artworks',
  all_artworks: 'All the artworks by your request',
  educations: 'Educational courses',
  all_educations: 'All educational courses',
  artists: 'Artists',
  all_artists: 'All the artists by your request',
  exhibitions: 'Exhibitions',
  all_exhibitions: 'All the exhibitions by your request',
  institutions: 'Museums',
  all_institutions: 'All the institutions by your request',
};
