/* eslint-disable max-len */
export default {
  anew: 'Once again',
  loading: 'Loading',
  unsupported_file: 'Invalid file',
  size_too_big: 'The size is too big',
  maximum_size: 'The maximum size is {{ maxFileSize }}',
  error: 'Error',
  delete: 'Delete',
  download: 'Download',
  upload: 'Upload',
  payment: 'Payment',
  reset: 'Reset',
  maximum_mb: 'The maximum file size is {{ maxFileSize }}MB',
  minimum_mb: 'The minimum file size is  {{ minFileSize }}KB',
  file_format: 'Supported file formats {{ fileTypes }}',
  maximum_number_files: 'Maximum number of files',
  maximum_total_size_exceeded: 'Maximum total size is exceeded',
  was_reset: 'Reset',
  completed: 'Сompleted',
  cancel: 'Cancel',
  return: 'Return',
  upload_error: 'Upload error',
  select_file: 'Select a file',
  select_photo: 'Select a photo',
  photo_preview: 'Photo preview',
  action: 'Action',
  name: 'Name',
  remove: 'Delete',
  preparation_img: 'Preparing the photo',
  rotate: 'Rotate',
  size_1: 'Size 1',
  cut_save: 'Cut and save',
  upload_image: 'Upload an image',
  upload_file: 'Upload a file',
  upload_info: 'Use the button or drag',
  file_in_area: 'a file to this area',
  image_in_area: 'a photo to this area',
};
