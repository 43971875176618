/* eslint-disable max-len */
export default {
  user: {
    info: 'User pages have changed!',
    text: 'In profile you can see all information about you and check how it is reflected at other users',
    look: 'Please note: editing collections, works and events',
    move: 'moved here',
    thanks: 'I see, thank you',
  },
};
